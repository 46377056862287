export default function ({ redirect, route }) {
  const isMaintenance = process.env.NUXT_ENV_MAINTENANCE || 'false'
  if (route.path === '/') {
    return redirect('/chuyen-de')
  } else if (isMaintenance === 'true') {
    return redirect('/maintenance')
  } else if (isMaintenance === 'false' && route.path === '/maintenance') {
    return redirect('/chuyen-de')
  }
}
