<template>
  <client-only>
    <nuxt-img :preset="preset" :src="src" :width="width" :height="height" :quality="quality" :sizes="sizes" :fit="fit" format="webp" @error.native="setSrcWhenError($event)" loading="lazy"/>
  </client-only>
</template>

<script>

export default {

  props: {
    preset: {
      type: String,
      default: null
    },
    sizes: {
      type: String,
      default: null
    },
    format: {
      type: String,
      default: 'webp'
    },
    src: {
      type: String,
      default: ''
    },
    width: {
      type: [Number, String],
      default: null
    },
    height: {
      type: [Number, String],
      default: null
    },
    quality: {
      type: [Number, String],
      default: 90
    },
    fit: {
      type: [String],
      default: 'cover'
    }
  },
  methods: {
    setSrcWhenError ($event) {
      switch (this.src) {
        case 'avatar':
          $event.target.src = require('~/assets/images/icons/no_avatar.svg')
          break
        case 'video':
          $event.target.src = require('~/assets/images/no-image.png')
          break
        case 'document':
          $event.target.src = require('~/assets/images/no-image.png')
          break
        case 'lesson':
          $event.target.src = require('~/assets/icons/library/no-video.png')
          break
        default:
          $event.target.src = require('~/assets/images/no-image.png')
          break
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
