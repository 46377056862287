<template>
  <div class="ml-khoa-hoc mb-5">
    <div class="container">
      <div class="row">
        <!--        <div class="col-lg-2 offset-lg-1 col-xl-2 offset-xl-0 d-none d-lg-block">-->
        <!--          <MenuLeft @selectClass="findClass"/>-->
        <!--        </div>-->
        <div class="col-lg-10 col-xl-12">
          <div class="d-flex justify-content-between">
            <CategorySelect class="d-none d-lg-block" placeholder="Môn" @findCategory="findCategory" :library-show="true"/>
            <FormSearch :value="keyword" @enter="handerSearch"/>
          </div>
          <div v-if="keyword" class="text-primary text-break mt-3">
            {{ query.total }} kết quả <span class="d-none d-lg-inline">tìm kiếm</span> cho <span class="d-none d-lg-inline">từ khóa</span> "<b>{{ keyword }}</b>"
          </div>
          <div class="hot-topic mt-3" v-if="topic.ADVANCED.length && !keyword">
            <div class="title d-flex align-items-center">Khóa học chuyên sâu
              <nuxt-link v-if="topic.ADVANCED.length > 4" to="/khoa-hoc/chi-tiet/nang-cao" class="view-more ml-auto pointer">Xem thêm</nuxt-link>
            </div>
            <VueSlickCarousel v-bind="carouselSettings">
              <VideoTopicItem v-for="item in topic.ADVANCED" :key="item.id" :item="item" @showNotify="showNotify"/>
              <template #prevArrow>
                <ArrowLeft/>
              </template>
              <template #nextArrow>
                <ArrowRight/>
              </template>
            </VueSlickCarousel>
          </div>
          <div class="hot-topic mt-3" v-if="topic.BASIC.length && !keyword">
            <div class="title d-flex align-items-center">Khóa học cơ bản
              <nuxt-link v-if="topic.BASIC.length > 4" to="/khoa-hoc/chi-tiet/co-ban" class="view-more ml-auto pointer">Xem thêm</nuxt-link>
            </div>
            <VueSlickCarousel v-bind="carouselSettings">
              <VideoTopicItem v-for="item in topic.BASIC" :key="item.id" :item="item" @showNotify="showNotify"/>
              <template #prevArrow>
                <ArrowLeft/>
              </template>
              <template #nextArrow>
                <ArrowRight/>
              </template>
            </VueSlickCarousel>
          </div>
          <div class="hot-topic mt-3" v-if="topic.ME.length && !keyword">
            <div class="title d-flex align-items-center">Khóa học của tôi
              <nuxt-link v-if="topic.ME.length > 3" to="/thu-vien-cua-toi/khoa-hoc/dang-hoc" class="view-more ml-auto pointer">Xem thêm</nuxt-link>
            </div>
            <VueSlickCarousel v-bind="carouselSettings">
              <VideoTopicItem v-for="item in topic.ME" :key="item.id" :item="item" @showNotify="showNotify"/>
              <template #prevArrow>
                <ArrowLeft/>
              </template>
              <template #nextArrow>
                <ArrowRight/>
              </template>
            </VueSlickCarousel>
          </div>
          <div class="topic mt-3" v-if="keyword">
            <ViewMode @mode="changeMode" title="Video"/>
            <div class="row list-item">
              <VideoItem v-for="item in listData" :key="item.id" :class="{'topic-item-list col-6 col-md-4 col-xl-3': viewList, 'topic-item-detail col-sm-12': !viewList}" :view-list="viewList" :item="item"/>
            </div>
            <NoData v-if="!listData.length && !loading" text="Nội dung đang cập nhật"/>
            <Pagination :total="query.total" :value="query.page" @change="loadData" :limit="query.limit"/>
          </div>
        </div>
      </div>
      <PlaylistNotify :flag-notify="flagNotify" :text-notify="textNotify" @hide="flagNotify = false"/>
    </div>
  </div>
</template>

<script>
// import MenuLeft from '~/components/mlearn/layout/menu/left.vue'
import FormSearch from '~/components/mlearn/select/form-search.vue'
import CategorySelect from '~/components/mlearn/select/category.vue'
import VideoTopicItem from '~/components/mlearn/video/topic.vue'
import PlaylistNotify from '~/components/mlearn/playlist/notify.vue'
import VideoItem from '~/components/mlearn/video/item.vue'
import Pagination from '~/components/mlearn/pagination/index'
import ViewMode from '~/components/mlearn/view-mode/index.vue'
import Resource from '~/common/api/resource'
import {EventBus} from '~/common/utils/eventBus'

const topicResource = new Resource('library/topic')
const videoMeResource = new Resource('library/topic/viewed')
const fileResource = new Resource('library/file')

export default {
  components: {
    // MenuLeft,
    FormSearch,
    CategorySelect,
    VideoTopicItem,
    PlaylistNotify,
    VideoItem,
    Pagination,
    ViewMode
  },
  layout: 'mlearn',
  asyncData ({isDev, route, store, env, params, req, res, redirect, error}) {
    const query = {
      category_id: '',
      class_id: '',
      type: 'video',
      keyword: '',
      page: 1,
      limit: 12,
      total: 0,
      classify: ''
    }
    const storeData = store.state.storeRedirect
    if (storeData) {
      query.category_id = storeData.category_id || ''
      query.class_id = storeData.class_id || ''
    }
    return {
      topic: {
        BASIC: [],
        ADVANCED: [],
        ME: []
      },
      listData: [],
      query,
      keyword: '',
      viewList: true,
      flagNotify: false,
      textNotify: ''
    }
  },
  data () {
    return {
      carouselSettings: {
        arrows: true,
        dots: false,
        infinite: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1920,
            settings: {
              slidesToShow: 4
            }
          },
          {
            breakpoint: 1366,
            settings: {
              slidesToShow: 3.8
            }
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 2.5
            }
          },
          {
            breakpoint: 576,
            settings: {
              slidesToShow: 1.2
            }
          }
        ]
      }
    }
  },
  async created () {
    EventBus.$on('handleChangeClassMenu', this.handleChangeClassMenu)
    this.$store.dispatch('setStoreRedirect', null)
    await this.loadData(1, 'BASIC')
    await this.loadData(1, 'ADVANCED')
    this.loadDataMe()
  },
  destroyed () {
    EventBus.$off('handleChangeClassMenu', this.handleChangeClassMenu)
  },
  methods: {
    changeMode (mode) {
      this.viewList = mode
    },
    reset () {
      this.listData = []
      this.query = {
        category_id: '',
        class_id: '',
        type: 'video',
        keyword: '',
        page: 1,
        limit: 12,
        total: 0,
        classify: ''
      }
      this.keyword = ''
    },
    async findClass (classId) {
      this.query.class_id = classId
      this.query.page = 1
      this.query.total = 0
      await this.loadData(1, 'BASIC')
      await this.loadData(1, 'ADVANCED')
      this.loadDataMe()
    },
    async findCategory (categoryId) {
      this.query.category_id = categoryId
      this.query.page = 1
      this.query.total = 0
      await this.loadData(1, 'BASIC')
      await this.loadData(1, 'ADVANCED')
      this.loadDataMe()
    },
    async loadData (page = 1, classify = '') {
      if (classify) {
        this.query.classify = classify
      }
      this.listData = []
      this.query.page = page
      if (this.loading) {
        return
      }
      this.loading = true
      let data
      if (!this.keyword) {
        data = await topicResource.list(this.query)
        this.topic[classify] = data.data || []
      } else {
        data = await fileResource.list(this.query)
        this.listData = data.data || []
      }
      this.query.total = data.total || 0
      this.loading = false
    },
    async loadDataMe (page = 1) {
      this.listData = []
      this.query.page = page
      if (this.loading) {
        return
      }
      this.loading = true
      const data = await videoMeResource.list(this.query)
      this.topic.ME = data.data || []
      this.loading = false
    },
    handerSearch (keyword) {
      this.query.keyword = keyword
      this.query.classify = ''
      this.keyword = keyword
      this.loadData()
    },
    showNotify (text) {
      this.flagNotify = false
      this.textNotify = text
      this.flagNotify = true
    },
    handleChangeClassMenu (obj) {
      this.findClass(obj.id)
    }
  }
}
</script>

<style lang="scss">
.ml-khoa-hoc {
  .hot-topic {
    .slick-slider {
      .slick-list {
        margin: 0 -9px;

        .slick-track {
          .slick-slide {
            padding: 10px 9px;
            @media (max-width: 1366px) {
              padding: 10px 5px;
            }
            @media (max-width: 576px) {
              padding: 10px 5px;
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.ml-khoa-hoc {
  .title {
    font-weight: bold;
    font-size: 24px;
    @media (max-width: 1366px) {
      font-size: 18px;
    }
    @media (max-width: 576px) {
      font-size: 16px;
    }
  }

  .view-more {
    font-weight: 500;
    color: #65676B;
    font-size: 16px;
    @media (max-width: 1366px) {
      font-size: 15px;
    }
    @media (max-width: 576px) {
      font-size: 14px;
    }
  }

  .hot-topic {
    background: var(--white);
    box-shadow: 0px 2px 4px rgba(202, 202, 202, 0.34);
    border-radius: 12px;
    padding: 20px 20px 10px 20px;
    @media (max-width: 1366px) {
      padding: 18px;
    }
    @media (max-width: 576px) {
      padding: 16px;
    }
  }

  .topic {
    background: var(--white);
    box-shadow: 0px 2px 4px rgba(202, 202, 202, 0.34);
    border-radius: 12px;
    padding: 20px 20px 32px 20px;
    @media (max-width: 1366px) {
      padding: 18px;
    }
    @media (max-width: 576px) {
      padding: 16px;
    }

    .list-item {
      .topic-item-list {
        padding-top: 18px;
        @media (max-width: 1366px) {
          padding-top: 15px;
        }
        @media (max-width: 576px) {
          padding-top: 10px;
        }
      }

      .topic-item-detail {
        padding-top: 12px;
        padding-bottom: 12px;
        border-bottom: 1px solid #EBEBEB;
        @media (max-width: 1366px) {
          padding-top: 10px;
          padding-bottom: 10px;
        }
        @media (max-width: 576px) {
          padding-top: 8px;
          padding-bottom: 8px;
        }

        &:first-child {
          padding-top: 20px;
          @media (max-width: 1366px) {
            padding-top: 15px;
          }
          @media (max-width: 576px) {
            padding-top: 10px;
          }
        }

        &:last-child {
          border-bottom: 0;
        }
      }
    }
  }
}
</style>
