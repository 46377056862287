<template>
  <div class="content-introduce container">
    <client-only>
      <h4 class="font-weight-bold text-center mt-3" style="font-size: 18px;">BẢO HÀNH VÀ QUẢN LÝ KHIẾU NẠI</h4>
      <p>Người học được ph&eacute;p hủy đơn h&agrave;ng trong trường hợp chưa đăng nhập v&agrave;o hệ thống học tập trực tuyến của Vilearn, tuy nhi&ecirc;n phải chịu c&aacute;c khoản ph&iacute; giao dịch v&agrave; c&aacute;c khoản Thuế với kh&oacute;a học đ&atilde; thanh to&aacute;n;</p>
      <p>Trong trường hợp lỗi ho&agrave;n to&agrave;n từ ph&iacute;a Vilearn, sau khi hai b&ecirc;n trao đổi sẽ thống nhất c&aacute;ch thức ho&agrave;n trả bồi thường dịch vụ.</p>
      <div class="text-justify text-break font-weight-bold pt-2">Giải quyết khiếu nại</div>
      <p>Tất cả nội dung trang web v&agrave; &yacute; kiến ph&ecirc; b&igrave;nh của qu&yacute; kh&aacute;ch đều l&agrave; t&agrave;i sản của ch&uacute;ng t&ocirc;i. Nếu ch&uacute;ng t&ocirc;i ph&aacute;t hiện bất kỳ th&ocirc;ng tin giả mạo n&agrave;o, xo&aacute; bỏ th&ocirc;ng tin đ&oacute; hoặc kh&oacute;a t&agrave;i khoản của qu&yacute; kh&aacute;ch ngay lập tức hoặc &aacute;p dụng c&aacute;c biện ph&aacute;p kh&aacute;c theo quy định của ph&aacute;p luật Việt Nam.</p>
      <p>Mọi th&ocirc;ng tin hỗ trợ xin li&ecirc;n hệ bộ phận chăm s&oacute;c kh&aacute;ch h&agrave;ng:</p>
      <ul>
        <li><strong>support@Vilearn.vn</strong></li>
      </ul>
    </client-only>
    </div>
</template>
<script>
export default {
  name: 'DieuKhoanSuDung'
}
</script>

<style scoped>
.content-introduce{
  padding: 15px;
}
ul{
  margin-left: 40px !important;
}
</style>
