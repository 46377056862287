<template>
  <b-modal id="modal-redirect-sub" v-model="showModal" hide-header hide-footer centered no-close-on-backdrop no-close-on-esc body-class="position-relative">
    <img alt="logo" src="~/mlearn/icons/menu/vilearn.svg" class="logo">
    <img alt="logo" src="~/mlearn/icons/modal/close-modal.svg" class="close pointer position-absolute" @click="showModal = false">
    <div class="title pt-4">Bạn cần mua gói Sub để xem nội dung này!</div>
    <div class="action d-flex">
      <button class="detail" @click="redirectSub">Chi tiết gói sub</button>
      <button class="buy-now ml-auto" @click="redirectSub">Mua ngay</button>
    </div>
  </b-modal>
</template>
<script>
export default {
  data () {
    return {
      showModal: false
    }
  },
  methods: {
    redirectSub () {
      this.$router.push('/goi-cuoc?sub=1')
      this.$bvModal.hide('modal-redirect-sub')
    }
  }
}
</script>

<style lang="scss">
#modal-redirect-sub{
   &___BV_modal_outer_{
      z-index: 9999 !important;
      .modal-dialog{
        max-width:420px;
        .modal-body{
          padding:24px;
          @media (max-width:576px) {
            padding:16px;
          }
          @media (max-width:1366px) {
            padding:18px;
          }
          .close{
            right: 10px;
            top: 10px;
          }
          .title{
            font-size: 15px;
            text-align: center;
          }
          .action{
            padding-top:20px;
            @media (max-width:576px) {
              padding-top:15px;
            }
            button{
              font-size: 14px;
              text-align: center;
              color: var(--white);
              border-radius: 20px;
            }
            .detail{
              background: #75777B;
              padding:10px 20px;
              @media (max-width:576px) {
                padding:8px 20px;
              }
              @media (max-width:1366px) {
                padding:8px 20px;
              }
            }
            .buy-now{
              background: var(--primary);
              padding:10px 30px;
              @media (max-width:576px) {
                padding:8px 30px;
              }
              @media (max-width:1366px) {
                padding:8px 30px;
              }
            }
          }
        }
      }
   }
  .logo{
    width: 147px;
    margin: auto 30%;
  }
}
</style>
