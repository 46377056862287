<template>
  <div class="ml-gioi-thieu container container-mobile mb-5">
    <div class="content-introduce">
      <client-only>
        <SlideBanner type="BANNER_HOME_SLIDE" />
        <!--                <AppLink />-->
        <OnlineClass />
        <Questioning />
        <Teachers />
        <Package />
      </client-only>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import OnlineClass from '~/components/hoi-bai-new/online-class.vue'
import Questioning from '~/components/hoi-bai-new/questioning.vue'
import Package from '~/components/hoi-bai-new/Package-gt.vue'
import Teachers from '~/components/hoi-bai-new/teachers.vue'
import SlideBanner from '~/components/gioi-thieu/slide.vue'
import Resource from '~/common/api/resource'
import TravisAes from '~/common/utils/TravisAes'
const metaResource = new Resource('config/seo')
const sendEmail = new Resource('user/sendEmail')
export default {
  components: {
    SlideBanner,
    // BannerItem,
    // AppLink,
    Questioning,
    Package,
    OnlineClass,
    Teachers
  },
  layout: 'Vilearn',
  async asyncData ({ isDev, route, store, env, params, query, req, res, redirect, error }) {
    const meta = await metaResource.list({ path: route.path }).catch(() => {})
    const metaSeo = meta?.data || null
    const structuredData = {}
    return { metaSeo, structuredData }
  },
  head () {
    if (!this.metaSeo) {
      return {}
    }
    return {
      title: (this.metaSeo?.title || ''),
      meta: [
        { hid: 'title', name: 'title', content: (this.metaSeo?.title || '') },
        { hid: 'description', name: 'description', content: (this.metaSeo?.description || '') },
        { hid: 'keywords', name: 'keywords', content: (this.metaSeo?.keyword || '') },
        { hid: 'og:type', property: 'og:type', content: 'website' },
        { hid: 'og:title', property: 'og:title', content: (this.metaSeo?.title || '') },
        { hid: 'og:url', property: 'og:url', content: (process.env.NUXT_ENV_WEB_URL + this.$route.fullPath) },
        { hid: 'og:description', property: 'og:description', content: (this.metaSeo?.description || '') },
        { hid: 'og:image', property: 'og:image', content: (this.metaSeo?.image || '') },
        { hid: 'twitter:card', property: 'twitter:card', content: 'summary_large_image' },
        { hid: 'twitter:title', property: 'twitter:title', content: (this.metaSeo?.title || '') },
        { hid: 'twitter:url', property: 'twitter:url', content: (process.env.NUXT_ENV_WEB_URL + this.$route.fullPath) },
        { hid: 'twitter:description', property: 'twitter:description', content: (this.metaSeo?.description || '') },
        { hid: 'twitter:image', property: 'twitter:image', content: (this.metaSeo?.image || '') }
      ],
      link: [
        { rel: 'canonical', href: process.env.NUXT_ENV_WEB_URL + this.$route.fullPath }
      ],
      script: [{ type: 'application/ld+json', json: this.structuredData }]
    }
  },
  computed: {
    ...mapState([
      'user'
    ])
  },

  methods: {
    sendIntroduce () {
      this.form.name = this.form.name.trim()
      this.form.email = this.form.email.trim()
      this.form.phone = this.form.phone.trim()
      this.form.description = this.form.description.trim()
      if (!this.form.name) {
        this.$notify({
          message: 'Tên của bạn không được để trống',
          type: 'error'
        })
        return
      }
      if (this.form.name.length > 200) {
        this.$notify({
          message: 'Họ tên không dài quá 200 ký tự',
          type: 'error'
        })
        return
      }
      if (!this.form.email) {
        this.$notify({
          message: 'Địa chỉ email không được để trống',
          type: 'error'
        })
        return
      }
      if (!this.$helper.validEmail(this.form.email)) {
        this.$notify({
          message: 'Địa chỉ email không đúng định dạng',
          type: 'error'
        })
        return
      }
      if (!this.form.phone) {
        this.$notify({
          message: 'Số điện thoại không được để trống',
          type: 'error'
        })
        return
      }
      if (!this.$helper.validPhone(this.form.phone)) {
        this.$notify({
          message: 'Số điện thoại không đúng định dạng',
          type: 'error'
        })
        return
      }
      if (!this.form.description) {
        this.$notify({
          message: 'Vui lòng nhập nội dung cần tư vấn',
          type: 'error'
        })
        return
      }

      if (this.form.description.length > 1000) {
        this.$notify({
          message: 'Nội dung không dài quá 1000 ký tự',
          type: 'error'
        })
        return
      }
      const aes = new TravisAes()
      // let content = 'Tên: ' + this.form.name + ', số điện thoại: ' + this.form.phone + ', email:' + this.form.email +', nội dung: ' + this.form.description;
      const content = '<html><body><b>Tên: </b><span>' + this.form.name + '</span><br><b>Số điện thoại: </b><span>' + this.form.phone + '</span><br><b>Email: </b><span>' + this.form.email + '</span><br><b>Nội dung: </b><span>' + this.form.description + '</span></body></html>'
      const emailTo = 'support@Vilearn.vn'
      const title = 'Thông tin liên hệ'
      const plainText = emailTo + title + content
      const sign = aes.encrypt(process.env.NUXT_ENV_PASSWORD_ENCRYPT, plainText)
      const user = {
        emailTo,
        title,
        content,
        sign
      }
      sendEmail.store(user)
        .then((response) => {
          if (response.status === 200) {
            this.$notify({
              title: 'Thành công',
              message: 'Liên hệ thành công! Chúng tôi sẽ gửi lại email cho bạn sớm nhất',
              type: 'success'
            })
            this.form = {
              name: '',
              email: '',
              phone: '',
              description: ''
            }
          }
        })
        .catch(() => {
          this.$notify.error({
            title: 'Có lỗi',
            message: 'Có lỗi xảy ra vui lòng thử lại sau!'
          })
        })
    }
  }
}
</script>
  <style lang="scss" scoped>
  .ml-gioi-thieu{
    .form-content{
      background: var(--white);
      box-shadow: 0px 2px 4px rgba(202, 202, 202, 0.34);
      border-radius: 8px;
      padding:24px;
      @media (max-width:576px) {
        box-shadow: none;
        border-radius: 0;
        padding:16px;
      }
      .header{
        border-bottom: 3px solid #EBEBEB;
        @media (max-width:576px) {}
        span{
          font-weight: 500;
          cursor: pointer;
          color: #65676B;
          font-size: 16px;
          margin-right: 40px;
          padding-bottom: 5px;
          @media (max-width:576px) {
            font-size: 14px;
            margin-right: 20px;
          }
          &:last-child{
            margin-right: 0;
          }
          &.active{
            position: relative;
            &::after{
              content: '';
              position: absolute;
              bottom: 0;
              left: 50%;
              transform: translateX(-50%);
              background: var(--primary);
              height: 3px;
              width:20px;
              @media (max-width:576px) {
                height: 2px;
                width:30px;
              }
            }
          }
        }
      }
      .content{
        > h4 {
          font-weight: bold;
          margin: 0;
          font-size: 24px;
          padding:40px 0 24px;
          @media (max-width:576px) {
            font-size: 16px;
            padding:20px 0 16px;
          }
        }
        .form-contact{
          background: #FAFAFA;
          border: 1px solid #EBEBEB;
          border-radius: 16px;
          padding:24px;
          margin-top: 40px;
          @media (max-width:576px) {
            border-radius: 10px;
            padding:16px;
            margin-top: 20px;
          }
          > h4 {
            font-weight: bold;
            margin-bottom: 0;
            font-size: 24px;
            padding:0 0 24px;
            @media (max-width:576px) {
              font-size: 16px;
              padding:0 0 16px;
            }
          }
          .group-item{
            margin-bottom: 16px;
            @media (max-width:576px) {
              margin-bottom: 10px;
            }
            .label{
              font-size: 12px;
            }
            input, textarea{
              background: var(--white);
              border: 1px solid #CED4DA !important;
              border-radius: 8px;
              width: 100%;
              font-size: 14px;
              resize: none;
              padding:10px 16px;
              @media (max-width:576px) {
                padding:8px 10px;
              }
              &::placeholder{
                opacity: .7;
              }
            }
            button{
              background: var(--primary);
              border-radius: 5px;
              color: var(--white);
              padding:10px 40px;
              @media (max-width:576px) {
                padding:8px 30px;
              }
            }
          }
          .form-data{
            margin-bottom: 16px;
            @media (max-width:576px) {
              margin-bottom: 10px;
            }
            .icon{
              margin-right: 16px;
              @media (max-width:576px) {
                margin-right: 10px;
              }
            }
            .title{
              font-size: 16px;
              opacity: 0.7;
              @media (max-width:576px) {
                font-size: 14px;
              }
            }
            .description{
              font-weight: 500;
              font-size: 16px;
              @media (max-width:576px) {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
    table td {
      border: 1px solid black;
      padding: 0 15px;
    }
  }
</style>
