<template>
  <div id="app" class="min-vh-100">
    <nuxt/>
  </div>
</template>
<script>
export default {

}
</script>
