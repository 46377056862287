<template>
  <div class="lib-inner-advance-filter" id="advance-filter" :class="{'show-advance-filter': show}">
    <div class="side-filter-wr pt-3">
      <div class="list-tag d-flex flex-wrap pb-3" v-if="listBookShow.length || listSubjectShow.length">
        <template v-for="(item, ind) in listBookShow">
          <div :key="'book-'+ind" v-if="booksChecked[item.id]" class="each-tag color-slightgrey mb-2 d-flex align-items-center justify-content-between" >
            {{ item.name }} <img class="pointer" src="~/assets/icons/library/close.svg" alt="" @click="removeFilter('booksChecked', item.id)" />
          </div>
        </template>
        <template v-for="(item, ind) in listSubjectShow">
          <div :key="'category-'+ind" v-if="subjectsChecked[item.id]" class="each-tag color-slightgrey mb-2 d-flex align-items-center justify-content-between">{{ item.name }} <img class="pointer" src="~/assets/icons/library/close.svg" alt="" @click="removeFilter('subjectsChecked', item.id)" /></div>
        </template>
        <template v-for="(item, ind) in listExamShow">
          <div :key="'exam-'+ind" v-if="examsChecked[item.value]" class="each-tag color-slightgrey mb-2 d-flex align-items-center justify-content-between">{{ item.label }} <img class="pointer" src="~/assets/icons/library/close.svg" alt="" @click="removeFilter('examsChecked', item.value)" /></div>
        </template>
        <div class="each-tag mb-2 d-flex align-items-center justify-content-between">Xóa tất cả <img class="pointer" src="~/assets/icons/library/close.svg" alt="" @click="removeFilter('all')"/></div>
      </div>
      <div class="filter-cate list-cate pb-3">
        <div class="each-cate" v-if="bookActive">
          <p class="cate-header color-darkgrey d-flex align-items-center pointer collapsed" data-toggle="collapse" data-target="#collapse-book" aria-expanded="true" aria-controls="collapse-book">
            <img src="~/assets/icons/library/book.svg" alt="" class="cate-icon">
            <span class="cate-name">Bộ sách</span>
            <img src="~/assets/icons/library/chevron-up.svg" alt="" class="expand-icon ml-auto">
          </p>
          <ul class="collapse multi-collapse show cate-items px-0" id="collapse-book">
            <li class="cate-item d-flex align-items-center pointer" v-for="(item, ind) in books" :key="ind">
              <input type="checkbox" name="checkbox-book" class="checkbox-books pointer" :checked="!!booksChecked[item.id]" :id="`checkbox-book-${ind}`" :value="item.id">
              <label :for="`checkbox-book-${ind}`" class="mb-0 w-100 item-label color-darkgrey pointer">{{item.name}}</label>
            </li>
          </ul>
        </div>
        <div class="each-cate" v-if="subjects.length">
          <p class="cate-header color-darkgrey d-flex align-items-center pointer collapsed" data-toggle="collapse" data-target="#collapse-subject" aria-expanded="true" aria-controls="collapse-subject">
            <img src="~/assets/icons/library/book-open.svg" alt="" class="cate-icon">
            <span class="cate-name">Môn học</span>
            <img src="~/assets/icons/library/chevron-up.svg" alt="" class="expand-icon ml-auto">
          </p>
          <ul class="collapse multi-collapse show cate-items px-0" id="collapse-subject">
            <li class="cate-item d-flex align-items-center pointer" v-for="(item, ind) in subjects" :key="ind">
              <input type="checkbox" name="subjects" class="checkbox-subjects pointer" :checked="!!subjectsChecked[item.id]" :id="`checkbox-subjects-${ind}`" :value="item.id">
              <label :for="`checkbox-subjects-${ind}`" class="mb-0 w-100 item-label color-darkgrey pointer">{{item.name}}</label>
            </li>
          </ul>
        </div>
        <div class="each-cate" v-if="exams.length">
          <p class="cate-header color-darkgrey d-flex align-items-center pointer collapsed" data-toggle="collapse" data-target="#collapse-exam-time" aria-expanded="true" aria-controls="collapse-subject">
            <img src="~/assets/icons/library/time-open.svg" alt="" class="cate-icon">
            <span class="cate-name">Thời gian</span>
            <img src="~/assets/icons/library/chevron-up.svg" alt="" class="expand-icon ml-auto">
          </p>
          <ul class="collapse multi-collapse show cate-items px-0" id="collapse-exam-time">
            <li class="cate-item d-flex align-items-center pointer" v-for="(item, ind) in exams" :key="ind">
              <input type="checkbox" name="exams" class="checkbox-exams pointer" :checked="!!examsChecked[item.value]" :id="`checkbox-exams-${ind}`" :value="item.value">
              <label :for="`checkbox-exams-${ind}`" class="mb-0 w-100 item-label color-darkgrey pointer">{{item.label}}</label>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import Resource from '~/common/api/resource'
const bookResource = new Resource('category/books')
const categoryResource = new Resource('library/subject')
const examTimeResource = new Resource('library/exam/time')
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
      required: false
    },
    type: {
      type: String,
      default: 'TOPIC',
      required: false
    }
  },
  data () {
    return {
      booksChecked: {},
      subjectsChecked: {},
      examsChecked: {},
      books: [
        { id: 'all', name: 'Tất cả' }
      ],
      subjects: [],
      exams: [],
      listBookShow: [],
      listSubjectShow: [],
      listExamShow: []
    }
  },
  computed: {
    ...mapState([
      'user'
    ]),
    ...mapGetters([
      'classes'
    ]),
    bookActive () {
      if (!this.user) { return true }
      const classAccept = [this.classes[0], this.classes[1], this.classes[4]]
      const ind = classAccept.findIndex(it => it?.id === this.user.classId)
      return (ind >= 0)
    }
  },
  watch: {
    $route () {
      this.listBookShow = Object.values(this.booksChecked)
      this.listSubjectShow = Object.values(this.subjectsChecked)
      this.listExamShow = Object.values(this.examsChecked)
    }
  },
  async mounted () {
    await this.loadCategory()
    await this.loadBook()
    if (this.$route.name === 'thu-vien-de-thi') {
      await this.loadExamTime()
    }
    const subjectsCheckbox = document.querySelectorAll('.checkbox-subjects')
    Array.from(subjectsCheckbox).forEach((element) => {
      element.addEventListener('click', () => this.setSubjects(element))
    })
    const booksCheckbox = document.querySelectorAll('.checkbox-books')
    Array.from(booksCheckbox).forEach((element) => {
      element.addEventListener('click', () => this.setBooks(element))
    })
    const examsCheckbox = document.querySelectorAll('.checkbox-exams')
    Array.from(examsCheckbox).forEach((element) => {
      element.addEventListener('click', () => this.setExams(element))
    })
    const query = { ...this.$route.query }
    if (query.category_id) {
      const categories = query.category_id.split(',')
      this.subjectsChecked = this.subjects.reduce((prev, current) => {
        if (categories.includes(current.id)) {
          return { ...prev, [current.id]: current }
        }
        return { ...prev }
      }, {})
    }
    if (query.book_id) {
      const books = query.book_id.split(',')
      this.booksChecked = this.books.reduce((prev, current) => {
        if (books.includes(current.id)) {
          return { ...prev, [current.id]: current }
        }
        return { ...prev }
      }, {})
    }
    if (query.time) {
      const times = query.time.split(',').map(Number)
      this.examsChecked = this.exams.reduce((prev, current) => {
        if (times.includes(current.value)) {
          return { ...prev, [current.value]: current }
        }
        return { ...prev }
      }, {})
    }
    this.listBookShow = Object.values(this.booksChecked)
    this.listSubjectShow = Object.values(this.subjectsChecked)
    this.listExamShow = Object.values(this.examsChecked)
  },
  methods: {
    async loadBook () {
      const { data } = await bookResource.list({ type: this.type, class_id: this.user?.classId })
      this.books.push(...data)
    },
    async loadExamTime () {
      const { data } = await examTimeResource.list()
      this.exams.push(...data)
    },
    async loadCategory () {
      const { data } = await categoryResource.list({ type: this.type, class_id: this.user?.classId })
      this.subjects = data
    },
    setSubjects (e) {
      if (e.checked) {
        const element = this.subjects.find(it => it?.id === e.value)
        this.subjectsChecked = {}
        this.subjectsChecked[e.value] = element
      } else {
        delete this.subjectsChecked[e.value]
      }
      const checkedIds = Object.keys(this.subjectsChecked).join()
      const query = { ...this.$route.query }
      if (checkedIds) {
        query.category_id = checkedIds
      } else {
        delete query.category_id
      }
      this.actionRedirect(query)
      // this.$router.push({ hash: 'collapse-book', query })
    },
    setBooks (e) {
      if (e.checked) {
        if (e.value === 'all') {
          this.booksChecked = {}
        } else {
          const element = this.books.find(it => it?.id === e.value)
          this.booksChecked = {}
          this.booksChecked[e.value] = element
        }
      } else {
        delete this.booksChecked[e.value]
      }
      const checkedIds = Object.keys(this.booksChecked).join()
      const query = { ...this.$route.query }
      if (checkedIds) {
        query.book_id = checkedIds
      } else {
        delete query.book_id
      }
      this.actionRedirect(query)
      // this.$router.push({ hash: 'advance-filter', query })
    },
    setExams (e) {
      if (e.checked) {
        const element = this.exams.find(it => it.value === parseInt(e.value))
        this.examsChecked = {}
        this.examsChecked[e.value] = element
      } else {
        delete this.examsChecked[e.value]
      }
      const checkedIds = Object.keys(this.examsChecked).join()
      const query = { ...this.$route.query }
      if (checkedIds) {
        query.time = checkedIds
      } else {
        delete query.time
      }
      this.actionRedirect(query)
      // this.$router.push({ hash: 'advance-filter', query })
    },
    actionRedirect (query = {}) {
      const queryString = Object.keys(query).map(key => key + '=' + query[key]).join('&')
      location.replace(`${location.origin}${location.pathname}?${queryString}`)
      // this.$router.push({ query })
    },
    removeFilter (type, key) {
      if (type === 'all') {
        this.booksChecked = {}
        this.subjectsChecked = {}
        const query = { ...this.$route.query }
        delete query.category_id
        delete query.book_id
        delete query.time
        this.actionRedirect(query)
        return
      }
      delete this[type][key]
      const checkedIds = Object.keys(this[type]).join()
      const query = { ...this.$route.query }
      if (checkedIds) {
        switch (type) {
          case 'booksChecked':
            query.book_id = checkedIds
            break
          case 'subjectsChecked':
            query.category_id = checkedIds
            break
          case 'examsChecked':
            query.time = checkedIds
            break
          default:
            break
        }
      } else {
        switch (type) {
          case 'booksChecked':
            delete query.book_id
            break
          case 'subjectsChecked':
            delete query.category_id
            break
          case 'examsChecked':
            delete query.time
            break
          default:
            break
        }
      }
      this.actionRedirect(query)
    }
  }
}
</script>

<style lang="scss" scoped>
  .lib-inner-advance-filter {
    box-flex: 0;
    transition: 0.2s ease-in-out;
    opacity: 0.6;
    transform: translateX(-.625rem);
    width: 0;
    height: 0;
    margin-top: 3rem;
    max-width: 18.7rem;
    @media (max-width: 1366px) {
      margin-top: 2rem;
      max-width: 15rem;
    }
    @media (max-width: 1200px) {}
    @media (max-width: 992px) {}
    @media (max-width: 768px) {}
    @media (max-width: 576px) {
      margin-top: 2rem;
      max-width: 10rem;
    }
    &.show-advance-filter {
      display: block;
      padding-right: 1rem !important;
      width: 100%;
      opacity: 1;
      transform: translateX(0px);
      height: auto;
      .side-filter-wr {
        visibility: visible;
        opacity: 1;
      }
    }
    .side-filter-wr {
      visibility: hidden;
      width: 100%;
      @media (max-width: 1366px) {}
      @media (max-width: 1200px) {}
      @media (max-width: 992px) {}
      @media (max-width: 768px) {}
      @media (max-width: 576px) {}
      .list-tag {
        gap: 0 .625rem;
        .each-tag {
          background: #ffffff;
          border-radius: 1rem;
          width: fit-content;
          transition: 0.2s ease-in-out;
          padding: .375rem .75rem;
          gap: .625rem;
          @media (max-width: 1366px) {}
          @media (max-width: 1200px) {}
          @media (max-width: 992px) {}
          @media (max-width: 768px) {}
          @media (max-width: 576px) {}
          &:hover {
            box-shadow: rgba(0, 0, 0, 0.06) 0px 3px 4px;
          }
        }
      }
      .list-cate {
        .expand-icon {
          transition: 0.2s ease;
        }
        .each-cate {
          padding: 1.5rem 0;
          @media (max-width: 1366px) {}
          @media (max-width: 1200px) {}
          @media (max-width: 992px) {}
          @media (max-width: 768px) {}
          @media (max-width: 576px) {}
          .cate-header {
            gap: .75rem;
            .cate-name {
              font-weight: 500;
              font-size: 1.1rem;
              @media (max-width: 1366px) {}
              @media (max-width: 1200px) {}
              @media (max-width: 992px) {}
              @media (max-width: 768px) {}
              @media (max-width: 576px) {}
            }
          }
          .cate-header[aria-expanded='false'] {
            .expand-icon {
              transform: rotateZ(180deg);
            }
          }
          .cate-items {
            list-style-type: none;
            .cate-item {
              gap: .75rem;
              &:not(:nth-last-of-type(1)) {
                margin-bottom: 1.1rem;
              }
              .item-label {
                font-size: 1rem;
                @media (max-width: 1366px) {}
                @media (max-width: 1200px) {}
                @media (max-width: 992px) {}
                @media (max-width: 768px) {}
                @media (max-width: 576px) {}
              }
            }
          }
        }
      }
    }
  }
</style>
<style lang="scss">
  #list-data{
    &.list-data{
      transition: all .5s;
      width: calc(100% - 18.7rem);
      @media (max-width: 1366px) {
        width: calc(100% - 15rem);
      }
      @media (max-width: 1200px) {}
      @media (max-width: 992px) {}
      @media (max-width: 768px) {}
      @media (max-width: 576px) {
        width: calc(100% - 10rem);
      }
    }
  }
</style>
