<template>
  <b-dropdown v-if="item" no-caret :ref="'dropdownSavePlaylist-'+item.id" menu-class="" right variant="tranparent p-0" class="dropdown-save-playlist-v2 position-absolute" @hidden="emitClose()" :class="classButton" toggle-class="shadow-none">
    <div class="title font-weight-bold">Lưu vào thư viện</div>
    <img class="close pointer position-absolute" src="~/assets/icons/library/close-modal.svg" @click="closeDropdown"/>
    <div class="mb-2">Chọn danh sách lưu</div>
    <div class="playlist-item list-it scrollbar pl-1">
      <div class="mb-2 mt-1" v-for="(playlist, ind) in playlists" :key="ind">
        <b-form-checkbox class="d-flex align-items-center" v-model="playlistId" :value="playlist.id" >{{playlist.name}}</b-form-checkbox>
      </div>
    </div>
    <div class="playlist-line"></div>
    <div class="playlist-item">
      <div class="mb-2 font-weight-bold">
        Tạo danh sách mới
      </div>
      <div class="">
        <input class="playlist-name shadow-none border-0 w-100" placeholder="Nhập tên danh sách" v-model="playlistName"/>
        <div v-if="textError" class="text-error">{{textError}}</div>
      </div>
    </div>
    <div class="action-new-playlist">
      <button class="btn text-center font-weight-bold bg-primary text-white w-100" @click="createPlaylist">Tạo danh sách</button>
    </div>
  </b-dropdown>
</template>
<script>
import Resource from '~/common/api/resource'
const playlistFileResource = new Resource('library/playlist/file')
const playlistExamResource = new Resource('library/playlist/exam')
const playlistResource = new Resource('library/playlist')
export default {
  props: {
    field: {
      type: String,
      default: 'exam_id',
      required: false
    },
    deleteId: {
      type: String,
      default: ''
    },
    classButton: {
      type: String,
      default: '',
      required: false
    },
    open: {
      type: Boolean,
      default: false,
      required: false
    },
    item: {
      type: Object,
      default: null,
      required: true
    }
  },
  data () {
    return {
      textError: '',
      playlistName: '',
      playlistId: ''
    }
  },
  computed: {
    playlists () {
      if (this.field === 'file_id') {
        return this.$store.getters.playlistDoc
      }
      return this.$store.getters.playlistExam
    }
  },
  watch: {
    open (new_, old_) {
      if (new_) {
        this.playlistId = ''
        this.textError = ''
        this.playlistName = ''
        this.openDropdown()
      }
    },
    playlistId () {
      if (this.playlistId) {
        const playlist = this.playlists.find(item => item.id === this.playlistId)
        if (!playlist) {
          const error = 'Playlist không tồn tại'
          this.$emit('showError', error)
          return
        }
        this.playlistName = playlist.name
        const form = {
          playlist_id: this.playlistId,
          playlist_name: playlist.name
        }
        this.emitData(form)
      }
    },
    deleteId (new_, old_) {
      if (new_) {
        if (this.field === 'video_id' || this.field === 'file_id') {
          playlistFileResource.destroy(new_)
            .then((res) => {
              this.$emit('addData', false)
              this.loadPlaylist()
            })
        } else {
          playlistExamResource.destroy(new_)
            .then((res) => {
              this.$emit('addData', false)
              this.loadPlaylist()
            })
        }
      }
    }
  },
  methods: {
    openDropdown () {
      const refs = 'dropdownSavePlaylist-' + this.item.id
      if (!refs) { return }
      this.$refs[refs].show(true)
    },
    closeDropdown () {
      const refs = 'dropdownSavePlaylist-' + this.item.id
      if (!refs) { return }
      this.$refs[refs].hide(true)
      this.emitClose()
    },
    emitClose () {
      this.$emit('close')
    },
    createPlaylist () {
      this.playlistName = this.playlistName.trim()
      if (!this.playlistName) {
        this.textError = '*Vui lòng nhập tên danh sách'
        return
      }
      if (this.playlistName.length > 100) {
        this.textError = 'Tên danh sách tối đa 100 ký tự'
        return
      }
      if (this.$helper.specialCharacters(this.playlistName)) {
        this.textError = 'Tên danh sách không được chứa ký tự đặc biệt'
        return
      }
      this.textError = ''
      const form = {
        playlist_name: this.playlistName
      }
      this.emitData(form)
    },
    emitData (data) {
      if (this.field === 'video_id' || this.field === 'file_id') {
        data.file_id = this.item.id
        playlistFileResource.store(data)
          .then((res) => {
            if (data.playlist_name) {
              this.loadPlaylist()
            }
            this.$emit('addData', true)
            this.closeDropdown()
          })
          .catch(() => {})
      } else {
        data[this.field] = this.item.id
        playlistExamResource.store(data)
          .then((res) => {
            if (this.playlistName) {
              this.loadPlaylist()
            }
            this.$emit('addData', true)
            this.closeDropdown()
          })
          .catch(() => {})
      }
    },
    loadPlaylist () {
      if (this.file_id === 'video_id' || this.field === 'file_id') {
        playlistResource
          .list({ limit: 100, type: 'doc' })
          .then((res) => {
            this.setPlaylist(res.data)
          })
          .catch(() => { })
      } else {
        playlistResource
          .list({ limit: 100, type: 'exam' })
          .then((res) => {
            this.setPlaylist(res.data)
          })
          .catch(() => {})
      }
    },
    setPlaylist (data = []) {
      if (this.file_id === 'video_id' || this.field === 'file_id') {
        this.$store.dispatch('context/setPlaylistDoc', data)
      } else {
        this.$store.dispatch('context/setPlaylistExam', data)
      }
    }
  }
}
</script>

<style lang="scss">
  .dropdown-save-playlist-v2{
    ul{
      background: var(--white);
      border: 1px solid #E3E3E3;
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.07) !important;
      border-radius: 16px;
      width: 328px;
      max-width: 100vw;
      padding:1rem 1.25rem;
      @media (max-width: 1366px) {
        padding:.75rem 1rem;
      }
      @media (max-width: 1200px) {}
      @media (max-width: 992px) {}
      @media (max-width: 768px) {}
      @media (max-width: 576px) {
      }
      @media (max-width:576px) {
        padding:1rem;
      }
      .title{
        font-size: 18px;
        padding-bottom:10px;
        @media (max-width:576px) {
          font-size: 1rem;
          padding-bottom:5px;
        }
        @media (max-width:576px) {
          font-size: .875rem;
          padding-bottom:5px;
        }
      }
      .close{
        width:14px;
        height:14px;
        top:19px;
        right:19px;
        @media (max-width:576px) {
          top:16px;
          right:16px;
        }
      }
      .playlist-item{
        &.list-it{
          min-height: 150px;
          max-height: 150px;
        }
        label{
          text-align: center;
          cursor: pointer;
          word-break: break-word !important;
          word-wrap: break-word !important;
          font-size: 16px;
          line-height: 1.5rem;
          padding-left: 10px;
          @media (max-width:576px) {
            font-size: 14px;
            padding-left: 5px;
          }
          &::before,&::after{
            width: 20px;
            height: 20px;
            @media (max-width:576px) {
              width: 15px;
              height: 15px;
            }
          }
        }
        .playlist-name{
          background: #E8ECF5;
          border-radius: 4px;
          font-size: 16px;
          padding:8px 10px;
          &::placeholder{
            color: #868789;
          }
          @media (max-width:576px) {
          }
        }
        .text-error{
          padding-top:8px;
          font-size: 13px;
          color: var(--danger);
          @media (max-width:576px) {}
        }
      }
      .playlist-line{
        border-top: 1px solid #EBEBEB;
        margin:10px 0;
      }
      .action-new-playlist{
        margin-top:15px;
        @media (max-width:576px) {}
        button{
          border-radius: 20px;
          height:40px;
          font-size: 16px;
          padding:7px 0;
          @media (max-width:576px) {}
        }
      }
    }
  }
</style>
