<template>
  <section class="home-page-section questioning-section">
    <div class="home-container">
      <div class="row mx-0">
        <div class="col-6 ls">
          <img src="/ui-new/images/question-intro-avg.png" id="lh0-preview-image" alt="qs-image-col" class="d-block ml-lg-3 mw-100 radio-banner">
        </div>
        <div class="col-6 rs d-flex flex-column ">
          <label class="position-relative mb-0 ">
            <p class="step-title-sub1 text-black-prim font-weight-bold">
              Hỏi bài
            </p>
            <p class="step-desc text-black-prim font-weight-normal">
              Tạo dựng công cụ giúp học sinh học tốt hơn
            </p>
          </label>
<!--          <div class="each-step">-->
<!--            <input type="radio" class="lh0-radio" id="test1" preview-image="question-intro-avg.png" checked>-->
<!--            <label for="test1" class="position-relative mb-0">-->
<!--              <div class="divider-h position-absolute"></div>-->
<!--              <p class="step-title text-black-prim">-->
<!--                Hỏi bài cộng đồng-->
<!--              </p>-->
<!--              <p class="step-desc text-black-prim font-weight-normal">-->
<!--                Tạo nền tảng hỗ trợ cộng đồng học sinh cùng giúp nhau học tập: người hỏi – người trả lời-->
<!--              </p>-->
<!--            </label>-->
<!--          </div>-->
          <div class="each-step">
            <input type="radio" class="lh0-radio" preview-image="question-intro-avg.png" id="test2" checked>
            <label for="test2" class="position-relative mb-0">
              <div class="divider-h position-absolute"></div>
              <p class="step-title text-black-prim">
                Hỏi bài có gia sư trả lời
              </p>
              <p class="step-desc text-black-prim font-weight-normal">
                Cung cấp đội ngũ gia sư giúp trả lời các câu hỏi nhanh và chính xác cho học sinh.
              </p>
            </label>
          </div>
          <div class="each-step">
            <input type="radio" class="lh0-radio" preview-image="question-intro-avg.png" id="test2" checked>
            <label for="test2" class="position-relative mb-0">
              <div class="divider-h position-absolute"></div>
              <p class="step-title text-black-prim">
                Hỏi bài AI
              </p>
              <p class="step-desc text-black-prim font-weight-normal">
                Sử dụng kho thư viện câu hỏi từ cộng động học sinh – gia sư (bigdata) và công nghệ AI giúp học sinh tìm kiếm nội dung nhanh chóng
              </p>
            </label>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
  .questioning-section {
		padding: 36px 0 56px;
		background-color: #ffffff;
		.home-page-section-title {
			font-weight: 800;
			font-size: 36px;
			line-height: 24px;
		}
		.rs {
			padding-top: 115px;
      padding-left: 56px;
      .step-title-sub1 {
        font-size: 20px;
        color: #4643f0;
        @media (max-width:1366px) {
          margin-bottom: 24px;
        }
        @media (max-width:992px) {
        }
      }
			.each-step {
				.step-title {
					font-weight: 700;
					font-size: 18px;
					line-height: 26px;
					margin-bottom: 12px;
				}
				.step-desc {
					font-size: 18px;
					line-height: 26px;
					margin-bottom: 40px;
				}
				.divider-h {
					width: 1px;
					height: 100%;
					background-color: #cbcbcb;
					left: 10px;
					bottom: 0;
					z-index: 3;
				}
				&:nth-last-of-type(1) {
					.divider-h {
						top: 0;
						height: 10px;
					}
				}
				&:nth-of-type(1) {
					.divider-h {
						top: 12px;
					}
				}
			}
		}
		[type='radio']:checked,
		[type='radio']:not(:checked) {
			position: absolute;
			left: -9999px;
		}
		[type='radio']:checked + label,
		[type='radio']:not(:checked) + label {
			position: relative;
			padding-left: 64px;
			cursor: pointer;
			line-height: 20px;
			display: inline-block;
			color: #000;
		}
		[type='radio']:not(:checked) + label:before {
			display: none;
		}
		[type='radio']:checked + label:before {
			z-index: 4;
			content: '';
			position: absolute;
			left: 0;
			top: 4px;
			width: 21px;
			height: 21px;
			border: 2px solid #4643f0;
			border-radius: 100%;
			background: #fff;
		}
		[type='radio']:not(:checked) + label:after {
			z-index: 4;
			content: '';
			width: 11px;
			height: 11px;
			background: #cbcbcb;
			position: absolute;
			top: 9px;
			left: 5px;
			border-radius: 100%;
			-webkit-transition: all 0.2s ease;
			transition: all 0.2s ease;
		}
		[type='radio']:checked + label:after {
			z-index: 4;
			content: '';
			width: 11px;
			height: 11px;
			background: #4643f0;
			position: absolute;
			top: 9px;
			left: 5px;
			border-radius: 100%;
			-webkit-transition: all 0.2s ease;
			transition: all 0.2s ease;
		}
		[type='radio']:checked + label:after {
			opacity: 1;
			-webkit-transform: scale(1);
			transform: scale(1);
		}
    @media only screen and (max-width: 992px) {
      padding: 36px 0 25px;
      .home-page-section-title {
        font-size: 28px;
        margin-bottom: 30px;
      }
      [type='radio']:checked + label, [type='radio']:not(:checked) + label {
        padding-left: 34px;
      }
      .rs {
        padding-top: 25px;
        padding-right: 0px;
        padding-left: 35px;
        .each-step:nth-of-type(1) .divider-h {
          top: 27px;
        }
        .each-step .step-title {
          font-size: 18px;
          line-height: 26px;
          margin-bottom: 8px;
        }
        .each-step .step-desc {
          font-weight: 400;
          font-size: 16px;
          line-height: 26px;
          margin-bottom: 28px;
        }
      }
      .ls {
        padding: 0 !important;
        .radio-banner {
          width: 365px;
          height: auto;
          max-width: unset;
          margin-left: 0 !important;
        }
      }
    }
    @media only screen and (max-width: 576px) {
      padding: 23px 0 25px;
      .home-page-section-title {
        font-size: 24px;
      }
      [type='radio']:checked + label, [type='radio']:not(:checked) + label {
        padding-left: 34px;
      }
      .ls {
        -webkit-box-flex: 0;
        -webkit-box-ordinal-group: 2;
        order: 1;
        flex: 0 0 100%;
        max-width: 100%;
        .radio-banner {
          width: 100%;
        }
      }
      .rs {
        -webkit-box-flex: 0;
        padding: 0;
        flex: 0 0 100%;
        max-width: 100%;
        .each-step:nth-of-type(1) .divider-h {
          top: 12px;
        }
        .each-step .step-title {
          font-size: 16px;
          line-height: 16px;
          margin-bottom: 6px;
        }
        .each-step .step-desc {
          font-size: 14px;
          line-height: 26px;
          margin-bottom: 12px;
        }
      }
    }
  }
</style>
