<template>
  <div class="player-video-wrapper bg-white position-relative overflow-hidden" :class="{'live': live}">
    <div class="content position-absolute w-100 h-100">
      <div v-if="backdrop" class="backdrop"></div>
      <img v-if="backdrop" class="action-play-video position-center pointer" style="left: 50%" src="~/assets/icons/library/play-video.svg" ref="handlePlay" @click="playVideo()"/>
      <div v-if="!backdrop && live" class="live-view position-absolute d-flex">
        <img src="~/assets/icons/library/live-video.svg"/>
        <span class="text-white font-weight-bold d-flex align-items-center justify-content-center"><img src="~/assets/icons/library/eye-video.svg"/> {{ numberLive }}</span>
      </div>
      <video height="auto" width="100%" ref="videoPlayer" class="video-js vjs-custom-skin vjs-big-play-centered vjs-live vjs-liveui vjs-waiting"></video>
    </div>
  </div>
</template>

<script>
import videojs from 'video.js'
import 'video.js/dist/video-js.css'
import 'videojs-contrib-quality-levels'
import videojsqualityselector from 'videojs-hls-quality-selector'
import Resource from '~/common/api/resource'

const videoResource = new Resource('library/video/watch-time')
export default {
  props: {
    item: {
      type: Object,
      default () {
        return null
      }
    },
    autoplay: {
      type: Boolean,
      default: false
    },
    muted: {
      type: Boolean,
      default: false
    },
    live: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      player: null,
      backdrop: true,
      options: {
        liveDurationInfinity: true,
        autoplay: this.autoplay,
        controls: true,
        muted: this.muted,
        preload: 'none', // auto,metadata,none
        language: 'vi',
        playbackRates: [0.5, 1.0, 1.5, 2.0],
        sources: [{
          type: this.item.file_type || 'video/mp4',
          src: this.item.url
        }],
        poster: this.item.thumbnail || this.item.thumb,
        notSupportedMessage: 'Không thể phát video.',
        controlBar: {
          playToggle: true,
          volumePanel: {
            inline: false,
            vertical: true
          },
          currentTimeDisplay: !this.live,
          timeDivider: true,
          durationDisplay: !this.live,
          progressControl: true,
          liveDisplay: true,
          seekToLive: true,
          remainingTimeDisplay: !this.live,
          customControlSpacer: true,
          playbackRateMenuButton: false,
          chaptersButton: true,
          descriptionsButton: true,
          subsCapsButton: true,
          audioTrackButton: true,
          fullscreenToggle: true,
          pictureInPictureToggle: true
        },
        liveui: true,
        fluid: true,
        currentTime: 0,
        aspectRatio: '16:9'
      },
      numberLive: 0,
      timer: null,
      timerMax: null,
      interval: 10000
    }
  },
  mounted () {
    this.player = videojs(this.$refs.videoPlayer, this.options)
    this.player.hlsQualitySelector = videojsqualityselector
    this.player.hlsQualitySelector()
    if (this.live) {
      this.backdrop = false
      const timeTooltip = this.player
        .getChild('controlBar')
        .getChild('progressControl')
        .getChild('seekBar')
        .getChild('PlayProgressBar')
        .getChild('timeTooltip')
      const ref = this
      timeTooltip.update = function () {
        // eslint-disable-next-line vue/no-mutating-props
        ref.item.duration_livestream = ref.item.duration_livestream + (1 / 100)
        this.write(ref.formatSeconds(ref.item.duration_livestream, true))
      }
    }
    this.player.on('playing', () => {
      this.backdrop = false
      if (this.item.second_view_free >= 0) {
        const maxTime = this.item.second_view_free + this.item.watch_time
        if (this.player !== null && this.player.currentTime() > maxTime) {
          this.player.currentTime(maxTime)
          this.player.pause()
          this.$store.dispatch('showStopModal', {
            type: 'VIDEO',
            classId: this.item.class_id,
            dataFree: this.item.video_free
          })
        }
        this.timerMax = setInterval(() => {
          if (this.player !== null && this.player.currentTime() > maxTime) {
            this.player.currentTime(maxTime)
            this.player.pause()
            this.$store.dispatch('showStopModal', {
              type: 'VIDEO',
              classId: this.item.class_id,
              dataFree: this.item.video_free
            })
          }
        }, 100)
      }
      this.timer = setInterval(() => {
        if (this.player !== null) {
          this.watchTime(this.interval / 1000)
        }
      }, this.interval)
    })
    this.player.on('pause', () => {
      // this.backdrop = true
      if (this.timer) {
        clearInterval(this.timer)
        this.timer = null
      }
      if (this.timerMax) {
        clearInterval(this.timerMax)
        this.timerMax = null
      }
      this.watchTime(0)
    })
    this.player.on('ended', () => {
      this.backdrop = true
      if (this.timer) {
        clearInterval(this.timer)
        this.timer = null
      }
      if (this.timerMax) {
        clearInterval(this.timerMax)
        this.timerMax = null
      }
    })
    this.player.on('timeupdate', () => {
      if (this.item.second_view_free >= 0) {
        const maxTime = this.item.second_view_free + this.item.watch_time
        if (this.player !== null && this.item.second_view_free >= 0 && this.player.currentTime() > maxTime) {
          this.player.currentTime(maxTime)
          this.player.pause()
          this.$store.dispatch('showStopModal', {
            type: 'VIDEO',
            classId: this.item.class_id,
            dataFree: this.item.video_free
          })
        }
      }
    })
  },
  sockets: {
    JOIN_CHANNEL (number) {
      this.numberLive = number
    }
  },
  beforeDestroy () {
    if (this.timer) {
      clearInterval(this.timer)
      this.timer = null
    }
    if (this.timerMax) {
      clearInterval(this.timerMax)
      this.timerMax = null
    }
    if (this.player) {
      this.player.dispose()
    }
  },
  methods: {
    playVideo () {
      this.player.play()
      this.backdrop = false
    },
    formatSeconds (secs, showSecond = false) {
      const secNum = parseInt(secs, 10)
      const hours = Math.floor(secNum / 3600)
      const minutes = Math.floor(secNum / 60) % 60
      const seconds = secNum % 60
      if (showSecond) {
        return [hours, minutes, seconds].map(v => v < 10 ? '0' + v : v).filter((v, i) => v !== '00' || i > 0).join(':')
      }
      if (hours >= 1) {
        return [hours, minutes].map(v => v < 10 ? '0' + v : v).filter((v, i) => v !== '00' || i > 0).join(':')
      }
      if (minutes) {
        return [minutes].map(v => v < 10 ? '0' + v : v).filter((v, i) => v !== '00' || i > 0).join(':')
      }
      return '00'
    },
    watchTime (secondActual) {
      if (this.player !== null) {
        let second = 0
        if (this.item.second_view_free >= 0) {
          const maxTime = this.item.second_view_free + this.item.watch_time
          second = Math.round(this.player.currentTime()) > maxTime ? maxTime : Math.round(this.player.currentTime())
        } else {
          second = Math.round(this.player.currentTime())
        }
        videoResource.store({
          video_id: this.item.id,
          second,
          second_actual: secondActual
        })
          .then(() => {
          })
          .catch(() => {
          })
      }
    }
  }
}
</script>

<style lang="scss">
.player-video-wrapper {
  padding-bottom: 56.25%;
  height: 0;

  .content {
    border: 1px solid #ccc;
    top: 0;
    left: 0;
    border-radius: 8px;

    .video-js {
      border-radius: 8px;

      video, .vjs-poster {
        border-radius: 8px;
      }

      .vjs-control {
        width: 3em;
      }

      .vjs-control-bar {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }

      .vjs-big-play-button {
        display: none;

        .vjs-icon-placeholder {
          &::before {
            content: '';
            background-image: url("/icon/library/play-video.svg");
            background-repeat: no-repeat;
            background-position-x: center;
          }
        }
      }

      .vjs-time-control {
        display: block;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        min-width: 0.5rem;
      }

      .vjs-time-divider {
        padding: 0;
      }

      .vjs-quality-selector {
        .vjs-menu-content {
          flex-direction: column-reverse;
          display: flex;

          .vjs-menu-item {
            text-transform: capitalize;
          }
        }
      }

      .vjs-progress-control {
        .vjs-progress-holder {
          background: rgba(255, 255, 255, 0.5);

          .vjs-load-progress {
            background: rgba(255, 255, 255, 0.5);
          }

          .vjs-play-progress {
            background: var(--primary);
          }
        }
      }

      .vjs-volume-panel {
        .vjs-volume-control {
          .vjs-volume-level {
            background: var(--primary);
          }
        }
      }
    }

    .backdrop {
      position: absolute;
      z-index: 900;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: 8px;
      background-color: rgba(0, 0, 0, 0.5);
    }

    .action-play-video {
      z-index: 990;
      width: 70px;
      height: 70px;
      @media (max-width: 576px) {
        width: 50px;
        height: 50px;
      }
    }

    .live-view {
      right: 1rem;
      top: 1rem;
      z-index: 99;
      gap: 6px;

      > span {
        background: rgba(000, 000, 000, .3);
        border-radius: 3px;
        height: 24px;
        width: 50px;
        gap: 5px;
      }
    }
  }

  &.live {
    .vjs-mouse-display {
      .vjs-time-tooltip {
        display: none !important;
      }
    }

    .vjs-play-progress {
      .vjs-time-tooltip {
        right: 0 !important;
      }
    }

    .vjs-time-control {
      display: none !important;
    }
  }
}
</style>
