<template>
  <div v-if="item">
    <div class="quiz-item-list position-relative pointer" v-if="viewList">
      <LockFile v-if="!item.is_free"/>
      <div class="quiz-name display-html display-2-line" @click="redirectDetail">{{item.name}}</div>
      <div class="quiz-subtitle">{{item.class_name}} &#8226; {{item.category_name}}</div>
      <div class="quiz-line"></div>
      <div class="row no-gutters list-action align-items-center">
        <div class="col-6">
          <img src="~/mlearn/icons/quiz/time.svg" alt="" class="mr-1" /> {{ item.time }} phút
        </div>
        <div class="col-6 text-right">
          <img src="~/mlearn/icons/quiz/number.svg" alt="" class="mr-1" /> {{ item.number_question }} câu
        </div>
        <div class="col-6 pt-2 number-user">
          {{ item.number_people }} người đã làm
        </div>
        <div class="col-6 pt-2">
          <button class="quiz-action bg-white text-center float-right" :class="{'active': item.is_saved}" @click="openDropdown">
            <span class="mr-1" v-html="require('~/assets/mlearn/icons/quiz/save.svg?raw')"></span>
            <span>{{item.is_saved ? 'Bỏ lưu' : 'Lưu'}}</span>
          </button>
          <Playlist v-if="action" field="exam_id" :item="item" :playlist="playlist" :open="open" @close="open = false" @addData="addData" @showError="showError" class-button="bottom-0 right-0" />
        </div>
      </div>
    </div>
    <div class="quiz-item-detail" v-else>
      <div class="form-data">
        <div class="content">
          <div class="d-flex align-items-center pointer" :title="item.name" @click="redirectDetail">
            <div class="quiz-name display-html display-1-line">{{item.name}}</div>
            <img v-if="!item.is_free" class="lock" src="~/mlearn/icons/quiz/lock-detail.svg" alt="Nội dung cần trả phí" />
          </div>
          <div class="quiz-subtitle"><span>{{item.class_name}}</span> &#8226; <span>{{item.category_name}}</span></div>
          <div class="row no-gutters list-action align-items-center">
            <div class="col-12 pt-2">
              <img src="~/mlearn/icons/quiz/time.svg" alt="" class="mr-1" /> {{ item.time }} phút
              <img src="~/mlearn/icons/quiz/number.svg" alt="" class="mr-1 ml-5" /> {{ item.number_question }} câu
            </div>
            <div class="col-9 col-lg-12 pt-2 number-user">
              {{ item.number_people }} người đã làm
            </div>
            <div class="col-3 d-lg-none">
              <div class="action pointer text-right" :class="{'active': item.is_saved}" @click="openDropdown">
                <div v-html="require('~/assets/mlearn/icons/quiz/save.svg?raw')"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-none d-lg-flex align-items-center">
          <div class="text-center action pointer" :class="{'active': item.is_saved}" @click="openDropdown">
            <div v-html="require('~/assets/mlearn/icons/quiz/save.svg?raw')"></div>
            <div>{{item.is_saved ? 'Bỏ lưu' : 'Lưu'}}</div>
          </div>
        </div>
      </div>
      <Playlist v-if="action" field="exam_id" :item="item" :playlist="playlist" :open="open" @close="open = false" @addData="addData" @showError="showError" class-button="right-0" />
    </div>
  </div>
</template>

<script>
import Resource from '~/common/api/resource'
import Playlist from '~/components/mlearn/playlist/index.vue'
const playlistExamResource = new Resource('library/playlist/exam')
export default {
  components: {
    Playlist
  },
  props: {
    item: {
      type: Object,
      default () {
        return null
      }
    },
    playlist: {
      type: Array,
      default () {
        return []
      }
    },
    slug: {
      type: String,
      default () {
        return ''
      }
    },
    viewList: {
      type: Boolean,
      default () {
        return true
      }
    },
    action: {
      type: Boolean,
      default () {
        return true
      }
    }
  },
  data () {
    return {
      textNotify: '',
      open: false,
      processing: false
    }
  },
  computed: {
    user () {
      return this.$store.state.user
    }
  },
  methods: {
    addData (form) {
      const message = `Đã lưu vào  "<b>${form.playlist_name}</b>"`
      if (form.playlist_id) {
        delete form.playlist_name
      }
      if (this.processing) { return }
      this.processing = true
      playlistExamResource.store(form)
        .then((res) => {
          if (res.status === 200) {
            this.open = false
            // eslint-disable-next-line vue/no-mutating-props
            this.item.is_saved = true
            this.textNotify = message
            this.showNotify()
          }
        })
        .finally(() => {
          this.processing = false
        })
    },
    showError (error) {
      this.textNotify = error
      this.showNotify()
    },
    UnSave () {
      if (this.processing) { return }
      this.processing = true
      playlistExamResource.destroy(this.item.id)
        .then((res) => {
          // eslint-disable-next-line vue/no-mutating-props
          this.item.is_saved = false
          this.textNotify = `Đã bỏ lưu "<b>${this.item.name}</b>"`
          this.showNotify()
        })
        .finally(() => {
          this.processing = false
        })
    },
    showNotify () {
      this.$emit('createdPlaylist')
      this.$emit('showNotify', this.textNotify)
    },
    redirectDetail () {
      if (this.slug) {
        this.$helper.checkUserBuySub(this, this.item.is_free, '/trac-nghiem/' + this.slug)
      } else {
        this.$helper.checkUserBuySub(this, this.item.is_free, '/trac-nghiem/' + this.item.slug)
      }
    },
    openDropdown () {
      if (!this.user) { // chưa đăng nhập
        this.$bvModal.show('modal-login')
        return
      }
      if (!this.action) {
        this.redirectDetail()
        return
      }
      if (this.item.is_saved) {
        this.UnSave()
        return
      }
      this.open = true
    }
  }
}
</script>

<style lang="scss" scoped>
  .quiz-item-list{
    background: var(--white);
    border: 1px solid #CED4DA;
    border-radius: 8px;
    padding:18px 16px 12px 16px;
    @media (max-width:576px) {
      padding:16px;
    }
    &:hover{
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
      .quiz-name{
        color: var(--primary);
      }
    }
    .quiz-name{
      white-space: break-spaces !important;
      font-weight: 600;
      min-height:50px;
      font-size: 16px;
      @media (max-width:576px) {
        min-height:45px;
        font-size: 14px;
      }
    }
    .quiz-line{
      height: 0;
      border: 0;
      border-top: 1px solid #EBEBEB;
      margin-top:10px;
      margin-bottom:16px;
      @media (max-width:576px) {
        margin-top:5px;
        margin-bottom:8px;
      }
    }
    .list-action{
      font-size: 12px;
      .number-user{
        color: #868789;
      }
    }
    .quiz-subtitle{
      font-size: 13px;
      font-weight: 500;
      color: var(--primary);
    }
    .quiz-action{
      border: 1px solid #868789 !important;
      border-radius: 1211px;
      padding:6px 0;
      width: 85px;
      font-size: 13px;
      color: #868789;
      @media (max-width:576px) {}
      &.active{
        border-color: var(--primary) !important;
        color: var(--primary);
      }
    }
  }
  .quiz-item-detail{
    background: var(--white);
    padding:12px;
    @media (max-width:576px) {
      padding:12px 0;
    }
    &:hover{
      .quiz-name{
        color: var(--primary);
      }
    }
    .form-data{
      .content{
        .quiz{
          width: 20px;
          margin-right: 10px;
          @media (max-width:576px) {}
        }
        .lock{
          width:16px;
          margin-left: 10px;
          @media (max-width:576px) {}
        }
        .quiz-name{
          font-weight: 600;
          font-size: 16px;
          @media (max-width:576px) {
            font-size: 14px;
          }
        }
        .quiz-subtitle{
          font-weight: 500;
          margin-top: 5px;
          color: var(--primary);
          font-size: 14px;
          @media (max-width:576px) {
            font-size: 13px;
          }
        }
        .list-action{
          font-size: 12px;
          .number-user{
            color: #868789;
          }
        }
      }
      .action{
        min-width: 40px;
        font-size: 14px;
        text-align: center;
        color: #828282;
        @media (max-width:576px) {}
        &.active{
          color: var(--primary);
        }
      }
    }
  }
  .quiz-action{
    padding-top:0;
    .save-file{
      width:12px;
      cursor: pointer;
    }
  }
</style>
<style lang="scss">
  .quiz-item-detail{
    .action{
      svg{
        width:12px;
        height:16px;
      }
    }
  }
</style>
