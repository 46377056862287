<template>
  <section class="quiz-inner-section pt-4 pt-xl-5">
    <div class="container lib-inner-container position-relative">
      <button id="advance-filter-btn" @click="toggleFilter" class="btn btn-advance-toggle-filter bg-transparent position-absolute p-0 color-darkgrey fw-500 btn-no-outline d-flex align-items-center" :class="{'collapsed': !toggle}" data-target="#advance-filter" aria-controls="advance-filter">
        <img v-if="!toggle" src="~/assets/icons/library/settings.svg" height="26px" class="setting-icon" title="Hiển thị bộ lọc" alt=""/>
        <img v-else src="~/assets/icons/library/close-dark.svg" height="26px" class="close-icon" title="Ẩn bộ lọc" alt=""/>
        <span class="d-none d-lg-block">Bộ lọc</span>
      </button>
      <div class="pt-2 pt-lg-3 d-flex">
        <MenuLeft :show="toggle" type="EXAM"/>
        <div class="list-data" :class="{'w-100': !toggle}" id="list-data">
          <FormSearch type="quiz"/>
          <div class="data-block text-nowrap bg-white p-0 my-3 my-xl-4">
            <div class="position-relative" v-if="topics.length">
              <div class="swiper p-3 p-xl-4" ref="swiper">
                <p class="data-block-title font-weight-bold color-darkgrey">Bộ đề VIP</p>
                <div class="swiper-wrapper align-items-stretch px-1">
                  <div class="swiper-slide inner-content-wt" v-for="(it,ind) in topics" :key="'topic-'+ind">
                    <QuizItem :item="it" :view-list="true" :action="false"/>
                  </div>
                </div>
              </div>
              <div class="container sw-nav-container">
                <div class="navigator-btn">
                  <div class="custom-nav-prev btn-nav-prev custom-navigator-btn d-flex align-items-center justify-content-center position-absolute bg-white rounded-circle pointer">
                    <img src="~/assets/icons/library/chevron-right.svg" alt=""></div>
                  <div class="custom-nav-next btn-nav-next custom-navigator-btn d-flex align-items-center justify-content-center position-absolute bg-white rounded-circle pointer">
                    <img src="~/assets/icons/library/chevron-right.svg" alt=""></div>
                </div>
              </div>
            </div>
            <div class="px-3 px-xl-4 mb-1 py-2 py-xl-3">
              <div class="d-flex align-items-center">
                <div class="data-block-title font-weight-bold mb-0 color-darkgrey display-html">Bộ đề</div>
                <span class="d-flex align-items-center ml-auto action flex-shrink-0">
                  <img src="~/assets/icons/library/list-grey.svg" :class="{'active': !viewList}" class="mr-2 pointer" alt="" @click="viewList = false">
                  <img src="~/assets/icons/library/app.svg" :class="{'active': viewList}" alt="" class="pointer" @click="viewList = true">
                </span>
                <div class="dropdown doc-types-dropdown ml-2 ml-xl-3">
                  <p class="btn d-flex align-items-center justify-content-between dropdown-toggle mb-0 selected-data fw-500" type="button" id="docDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{ placeholder }}
                    <img src="~/assets/icons/library/chevron-dropdown.svg" alt=""></p>
                  <div class="dropdown-menu dropdown-menu-right select-items pointer" aria-labelledby="docDropdown">
                    <div class="dropdown-item bg-transparent p-2" :class="{'on-active': query.order === ''}" @click="setOrder()">Tất cả</div>
                    <div class="dropdown-item bg-transparent p-2" :class="{'on-active': query.order === 'created_at'}" @click="setOrder('created_at')">Mới cập nhật</div>
                    <div class="dropdown-item bg-transparent p-2" :class="{'on-active': query.order === 'view'}" @click="setOrder('view')">Xem nhiều</div>
                  </div>
                </div>
              </div>
              <div class="data-divider mt-2"></div>
              <div v-if="loading" class="list-cards main-docs row my-3 my-xl-4">
                <SkeletonItem v-for="ind in 8" :key="ind" class="mb-2 mb-xl-3" :class="{'col-12': !viewList, 'col-12 col-lg-3': !toggle && viewList, 'col-12 col-lg-4': toggle && viewList }" :view-list="viewList"/>
              </div>
              <div v-else-if="datas.length" class="list-cards main-docs row my-3 my-xl-4">
                <QuizItem class="mb-2 mb-xl-3" :class="{'col-12': !viewList, 'col-12 col-lg-3': !toggle && viewList, 'col-12 col-lg-4': toggle && viewList }" :view-list="viewList" v-for="(it, ind) in datas" :key="ind" :item="it"/>
              </div>
              <NoData v-else/>
              <Pagination :page="query.page" :limit="3" :per-page="query.limit" :total="query.total" align="right" @change="loadData"/>
            </div>
          </div>
          <div class="data-block text-nowrap position-relative p-0 bg-white my-3 my-xl-4" v-if="user">
            <Viewed/>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {mapState} from 'vuex'
import cookieParser from 'cookieparser'
import Cookies from 'js-cookie'
import MenuLeft from '~/components/library-v2/MenuLeft.vue'
import FormSearch from '~/components/library-v2/FormSearch.vue'
import Viewed from '~/components/library-v2/Viewed.vue'
import Pagination from '~/components/library-v2/Pagination.vue'
import QuizItem from '~/components/library-v2/quiz/item.vue'
import SkeletonItem from '~/components/skeleton/quiz/item.vue'
import NoData from '~/components/nodata/new.vue'
import Resource from '~/common/api/resource'

const metaResource = new Resource('config/seo')
const quizResource = new Resource('library/exam')

export default {
  components: {
    MenuLeft,
    FormSearch,
    QuizItem,
    SkeletonItem,
    Viewed,
    Pagination,
    NoData
  },
  layout: 'mlearn',
  async asyncData ({isDev, route, store, env, query, req, res, redirect, error}) {
    const token = store.state.token
    const user = store.state.user
    let viewList = 1
    if (req && req.headers && req.headers.cookie) {
      const requestCookies = cookieParser.parse(req.headers.cookie)
      viewList = parseInt(requestCookies.viewList || 1)
    } else {
      viewList = parseInt(Cookies.get('viewList') || 1)
    }
    viewList = viewList === 1
    const meta = await metaResource.list({path: route.path}).catch(() => {
    })
    const metaSeo = meta?.data || null
    const structuredData = {}
    let topics = null
    try {
      const myQuery = {is_hot: 1, limit: 10, class_id: (user?.class_id || ''), category_id: (query?.category_id || ''), book_id: (query?.book_id || ''), time: (query?.time || '')}
      const {data} = await quizResource.list(myQuery, token).catch(() => {
      })
      topics = data
    } catch (e) {
      console.log('get fail quizResource', e)
    }
    const params = {
      class_id: '',
      category_id: '',
      book_id: '',
      time: (query?.time || ''),
      page: 1,
      limit: 8,
      keyword: '',
      order: '',
      total: 0
    }
    return {
      placeholder: 'Tất cả',
      query: params,
      topics,
      viewList,
      datas: [],
      loading: false,
      metaSeo,
      structuredData
    }
  },
  data () {
    return {
      mySwiper: null,
      suppend: true,
      toggle: false
    }
  },
  head () {
    if (!this.metaSeo) {
      return {}
    }
    return {
      title: (this.metaSeo?.title || ''),
      meta: [
        {hid: 'title', name: 'title', content: (this.metaSeo?.title || '')},
        {hid: 'description', name: 'description', content: (this.metaSeo?.description || '')},
        {hid: 'keywords', name: 'keywords', content: (this.metaSeo?.keyword || '')},
        {hid: 'og:type', property: 'og:type', content: 'website'},
        {hid: 'og:title', property: 'og:title', content: (this.metaSeo?.title || '')},
        {hid: 'og:url', property: 'og:url', content: (process.env.NUXT_ENV_WEB_URL + this.$route.fullPath)},
        {hid: 'og:description', property: 'og:description', content: (this.metaSeo?.description || '')},
        {hid: 'og:image', property: 'og:image', content: (this.metaSeo?.image || '')},
        {hid: 'twitter:card', property: 'twitter:card', content: 'summary_large_image'},
        {hid: 'twitter:title', property: 'twitter:title', content: (this.metaSeo?.title || '')},
        {hid: 'twitter:url', property: 'twitter:url', content: (process.env.NUXT_ENV_WEB_URL + this.$route.fullPath)},
        {hid: 'twitter:description', property: 'twitter:description', content: (this.metaSeo?.description || '')},
        {hid: 'twitter:image', property: 'twitter:image', content: (this.metaSeo?.image || '')}
      ],
      link: [
        {rel: 'canonical', href: process.env.NUXT_ENV_WEB_URL + this.$route.fullPath}
      ],
      script: [{type: 'application/ld+json', json: this.structuredData}]
    }
  },
  computed: {
    ...mapState([
      'user',
      'token'
    ])
  },
  watch: {
    $route () {
      this.loadTopic()
      this.loadData()
    },
    viewList () {
      if (this.viewList) {
        Cookies.set('viewList', 1)
      } else {
        Cookies.set('viewList', 0)
      }
    }
  },
  mounted () {
    this.loadData()
    const element = this.$refs.swiper
    this.mySwiper = this.getSwiper(element, 4, '.custom-nav-next', '.custom-nav-prev')
  },
  beforeDestroy () {
    if (Array.isArray(this.mySwiper)) {
      this.mySwiper.forEach(o => o?.destroy())
    } else {
      this.mySwiper?.destroy()
    }
  },
  methods: {
    getSwiper (element, slidesPerView, nextEl, prevEl) {
      if (!window.Swiper || !element) {
        return
      }
      return new window.Swiper(element, {
        spaceBetween: 24,
        keyboard: {
          enabled: true
        },
        autoplay: false,
        // autoplay: {
        //   delay: 4000,
        //   disableOnInteraction: false
        // },
        watchSlidesProgress: true,
        breakpointsBase: 'container',
        breakpoints: {
          400: {
            slidesPerView: 1
          },
          1000: {
            slidesPerView: (slidesPerView - 1)
          },
          1300: {
            slidesPerView
          }
        },
        navigation: {
          nextEl,
          prevEl
        },
        on: {
          beforeInit () {
          },
          init () {
          },
          afterInit () {
          }
        }
      })
    },
    toggleFilter () {
      this.toggle = !this.toggle
    },
    async loadTopic () {
      const query = {...this.$route.query}
      query.is_hot = 1
      query.limit = 10
      query.category_id = query.category_id || null
      query.time = query.time || ''
      query.book_id = query.book_id || null
      query.class_id = this.user?.classId || ''
      const {data} = await quizResource.list(query, this.token).catch(() => {
      })
      this.topics = data
    },
    async loadData (page = 1) {
      if (this.loading) {
        return
      }
      this.loading = true
      this.query.page = page
      const query = {...this.$route.query}
      this.query.category_id = query.category_id || null
      this.query.book_id = query.book_id || null
      this.query.time = query.time || ''
      this.query.class_id = this.user?.class_id || ''
      const {data, total} = await quizResource.list(this.query)
      this.datas = data

      this.query.total = total
      this.loading = false
      window.scroll({
        top: 0,
        behavior: 'smooth'
      })
    },
    setOrder (order = '') {
      this.query.order = order
      switch (order) {
        case 'created_at':
          this.placeholder = 'Mới cập nhật'
          break
        case 'view':
          this.placeholder = 'Xem nhiều'
          break
        default:
          this.placeholder = 'Tất cả'
          break
      }
      this.loadData()
    }
  }
}
</script>

<style lang="scss" scoped>
.quiz-inner-section {
  padding-bottom: 8rem;
  @media (max-width: 1366px) {
    padding-bottom: 4rem;
  }
  @media (max-width: 1200px) {
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 576px) {
    padding-bottom: 1.5rem;
  }

  .data-divider {
    height: 1px;
    background-color: #e6e6e6;
  }

  .swiper-slide {
    height: 9.5rem;
    width: calc(25% - 1.125rem);
    margin-right: 1.5rem;
    @media (max-width: 1449px) {
      width: calc(33% - 0.78rem);
    }
    @media (max-width: 1366px) {
      width: 100%;
    }
  }

  .btn-advance-toggle-filter {
    z-index: 1000;
    top: 1.5rem;
    left: 1rem;
    gap: 1rem;
    @media (max-width: 1366px) {
    }
    @media (max-width: 1200px) {
    }
    @media (max-width: 992px) {
    }
    @media (max-width: 768px) {
    }
    @media (max-width: 576px) {
      top: 1rem;
      left: .5rem;
      gap: .5rem;
    }
  }

  .list-data {
    .doc-types-dropdown {
      background: #f8f8f8;

      .selected-data {
        border: 1px solid #cbcbcb;
        border-radius: .5rem;
        padding: .625rem;
        font-size: .85rem;
        line-height: 1;
        min-width: 6.5rem;
        color: #888888;
        @media (max-width: 1366px) {
        }
        @media (max-width: 1200px) {
        }
        @media (max-width: 992px) {
        }
        @media (max-width: 768px) {
        }
        @media (max-width: 576px) {
        }
      }

      .select-items {
        border: 1px solid #cbcbcb;
        border-radius: .5rem;

        .dropdown-item {
          font-size: 1rem;
          line-height: 1.3;
          color: #a5a5a5;
          @media (max-width: 1366px) {
          }
          @media (max-width: 1200px) {
          }
          @media (max-width: 992px) {
          }
          @media (max-width: 768px) {
          }
          @media (max-width: 576px) {
          }

          &.active, &:active {
            background: transparent;
          }

          &.on-active {
            font-weight: 500;
            color: #333333;
          }
        }
      }
    }

    .data-block {
      border-radius: .5rem;

      &-title {
        line-height: 1.3;
        font-size: 1.5rem;
        @media (max-width: 1366px) {
          font-size: 1.25rem;
        }
        @media (max-width: 1200px) {
        }
        @media (max-width: 992px) {
        }
        @media (max-width: 768px) {
        }
        @media (max-width: 576px) {
        }
      }

      .action {
        .active {
          filter: brightness(0);
        }
      }

      .custom-navigator-btn {
        width: 2rem;
        height: 2rem;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
        border-radius: 100%;
        z-index: 99;
        top: calc(50% + 1.3rem);

        &.swiper-button-disabled {
          background-color: #828282bd !important;

          img {
            filter: brightness(0) invert(1) opacity(0.7);
          }
        }

        &.btn-nav-prev {
          left: 6px;
          transform: translateY(-50%);
        }

        &.btn-nav-next {
          right: 6px;
          transform: translateY(-50%);

          img {
            transform: rotateZ(180deg);
          }
        }
      }
    }
  }
}
</style>
