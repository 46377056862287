<template>
  <main id="app-hoi-bai" class="intro-page-main">
    <section class="intro-page-section intro-banner">
      <SlideBanner type="BANNER_HOME_SLIDE" />
<!--            <div class="qa-action home-container">-->
<!--              <div class="qaa-title">-->
<!--                <span class="title-left">Hỏi đáp</span> <span class="title-right">Gia sư</span>-->
<!--              </div>-->
<!--              <div class="qaa-description">-->
<!--                <p>Gia sư giải đáp tất cả các môn từ lớp 6 đến lớp 12</p>-->
<!--                <p>Nhanh chóng - Chính xác - Đáng tin cậy</p>-->
<!--              </div>-->

<!--              <div class="qaa-bonus">-->
<!--                Bạn còn lại <span>{{ tutorQuestion }}</span> lượt hỏi bài nhanh và <span>{{ videoQuestion }}</span> lượt hỏi bài video-->
<!--              </div>-->
<!--            </div>-->
    </section>
    <!--  -->
    <section class="home-page-section questions-section bg-white">
      <div class="container">
        <div class="row">
          <div class="col-3 qs-left d-lg-block d-none">
            <div class="qsl-title">
              Hỏi bài
            </div>
            <div class="qsl-subject">
              <nuxt-link to="/hoi-bai" class="qsls-item" :class="slug === '' ? 'active' : ''">
                <img src="~/assets/icons/question/all-subject.png" alt="Tất cả"> Tất cả
              </nuxt-link>
              <nuxt-link v-for="s in subjects" :key="s.id" :to="'/hoi-bai/' + s.slug" class="qsls-item" :class="s.slug === slug ? 'active' : ''">
                <img :src="'/icon/question/' + s.slug + '.png'" :alt="s.name"> {{ s.name }}
              </nuxt-link>
            </div>
            <div class="qsl-subject mt-4">
              <div class="item qsls-item-noi-quy form-data align-items-center" @click="goToPath('/noi-quy-hoi-bai')">
                <span class="text-center"><img src="~/mlearn/icons/menu/left/rules.svg" class="rules pointer" /></span>
                <div class="content pointer">Nội quy hỏi bài</div>
              </div>
            </div>
          </div>
          <div class="col-lg-9 col-12 qs-right">
            <FormSearch class="d-none d-lg-block mb-3" :value="query.keyword" @enter="searchQuestion" />
            <div class="d-flex align-items-center justify-content-between">
<!--              <p class="qsrl-title mb-0">-->
<!--                Câu hỏi đã được trả lời-->
<!--              </p>-->
<!--              <div class="qaa-btn pointer" @click="addQuestion">-->
<!--                <img src="~/assets/icons/question/btn-qa.png" alt="Đặt câu hỏi"> Đặt câu hỏi-->
<!--              </div>-->
              <button class="add-question d-flex align-items-center justify-content-center" @click="addQuestion">
                <img src="~/mlearn/icons/home/add-question.svg" alt="Đặt câu hỏi" class="mr-2"> Đặt câu hỏi</button>
              <div v-if="subjects.length" class="d-lg-none d-flex align-items-center qsrl-filter" @click="openFilter">
                <img src="~/assets/images/icons/filter.svg" /><p class="mb-0">Bộ lọc</p>
              </div>
            </div>
            <div class="qsr-list">
              <QuestionList v-if="listQuestion.length" :data="listQuestion" class="qsr-home-page" />
              <div v-else class="no-question">
                <img src="~/assets/images/no-question.png" alt="No question" class="d-block mx-auto"/>
                <p class="no-question-text text-center mt-4">Chưa có câu trả lời nào</p>
              </div>
              <Pagination :page="query.page" :limit="3" :per-page="query.limit" :total="total" align="center" @change="getListQuestion" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <AppsSection />
    <AddQuestion />
    <AddQuestionError />
    <section>
      <el-dialog
        center
        title="Bộ lọc"
        id="modal-filter-question"
        :visible.sync="showFilterDialog"
      >
        <FilterQuestion :subjects="subjects" :slug="slug" :path="'/hoi-bai'" @hide-dialog="showFilterDialog = false" />
      </el-dialog>
    </section>
  </main>
</template>

<script>
import Cookies from 'js-cookie'
import cookieParser from 'cookieparser'
import Pagination from '~/components/library-v2/Pagination.vue'
import SlideBanner from '~/components/gioi-thieu/slide.vue'
import AddQuestion from '~/components/modal/add-question.vue'
import AddQuestionError from '~/components/modal/add-question-error.vue'
import FormSearch from '~/components/mlearn/select/form-search.vue'
import FilterQuestion from '~/components/modal/filter-question.vue'
import QuestionList from '~/components/questions/index.vue'
// import ArchiveSection from '~/components/hoi-bai-new/archive.vue'
import AppsSection from '~/components/hoi-bai-new/apps.vue'
import Resource from '~/common/api/resource'
const metaResource = new Resource('config/seo')
const qaResource = new Resource('qa')
const remainResource = new Resource('guru/remain-guru-question')
// const checkPermissionResource = new Resource('qa/checkPermission')

export default {
  name: 'HoiBaiPage',
  components: {
    SlideBanner,
    Pagination,
    AddQuestion,
    AddQuestionError,
    FilterQuestion,
    QuestionList,
    FormSearch,
    AppsSection
    // ArchiveSection,
  },
  layout: 'mlearn',
  async asyncData ({ isDev, route, store, env, params, query, req, res, redirect, error }) {
    let classId = ''
    const structuredData = {}
    const user = store.state.user || {}
    const meta = await metaResource.list({ path: route.path }).catch(() => {})
    const metaSeo = meta?.data || null
    const oldSubjects = store.state.context.subjects
    const subjects = oldSubjects.filter(item => (item.tutor_question_show === 1))

    if (req && req.headers && req.headers.cookie) {
      const requestCookies = cookieParser.parse(req.headers.cookie)
      classId = requestCookies.interestedClass || user?.class_id
    } else {
      classId = Cookies.get('interestedClass') || user?.class_id
    }

    const queries = {
      slug: params.slug || '',
      question_status: 1,
      offset: 0,
      limit: 20,
      class_id: classId || '',
      category_id: ''
    }

    if (queries.slug !== '') {
      const subject = subjects.find(s => s.slug === queries.slug)
      if (subject) {
        queries.category_id = subject.id
      } else {
        route.push('/hoi-bai')
        return
      }
    }
    const { data, total } = await qaResource.list(queries).catch(() => {
      console.log('error')
    })
    return {
      user,
      total,
      metaSeo,
      subjects,
      structuredData,
      listQuestion: data,
      query: queries,
      slug: queries.slug
    }
  },
  data () {
    return {
      loading: true,
      keyword: '',
      errorCode: 403,
      errorContent: '',
      tutorQuestion: 0,
      videoQuestion: 0,
      showFilterDialog: false
    }
  },
  head () {
    if (!this.metaSeo) {
      return {}
    }
    return {
      title: (this.metaSeo?.title || ''),
      meta: [
        { hid: 'title', name: 'title', content: (this.metaSeo?.title || '') },
        { hid: 'description', name: 'description', content: (this.metaSeo?.description || '') },
        { hid: 'keywords', name: 'keywords', content: (this.metaSeo?.keyword || '') },
        { hid: 'og:type', property: 'og:type', content: 'website' },
        { hid: 'og:title', property: 'og:title', content: (this.metaSeo?.title || '') },
        { hid: 'og:url', property: 'og:url', content: (process.env.NUXT_ENV_WEB_URL + this.$route.fullPath) },
        { hid: 'og:description', property: 'og:description', content: (this.metaSeo?.description || '') },
        { hid: 'og:image', property: 'og:image', content: (this.metaSeo?.image || '') },
        { hid: 'twitter:card', property: 'twitter:card', content: 'summary_large_image' },
        { hid: 'twitter:title', property: 'twitter:title', content: (this.metaSeo?.title || '') },
        { hid: 'twitter:url', property: 'twitter:url', content: (process.env.NUXT_ENV_WEB_URL + this.$route.fullPath) },
        { hid: 'twitter:description', property: 'twitter:description', content: (this.metaSeo?.description || '') },
        { hid: 'twitter:image', property: 'twitter:image', content: (this.metaSeo?.image || '') }
      ],
      link: [
        { rel: 'canonical', href: process.env.NUXT_ENV_WEB_URL + this.$route.fullPath }
      ],
      script: [{ type: 'application/ld+json', json: this.structuredData }]
    }
  },
  methods: {
    goToPath (path) {
      this.$router.push(path)
    },
    async getRemainQuestion () {
      const { data } = await remainResource.list().catch(() => {})
      this.tutorQuestion = data.remain_question || 0
      this.videoQuestion = data.remain_video_question || 0
    },
    async getListQuestion (page = 1) {
      this.loading = true
      this.slug = this.$route.params.slug || ''
      this.query.offset = (page - 1) * this.query.limit

      if (this.slug !== '') {
        const subject = this.subjects.find(s => s.slug === this.slug)
        if (subject) {
          this.query.category_id = subject.id
          this.query.question_type = 2
        } else {
          this.$router.push('/hoi-bai')
          return
        }
      }

      const { data, total } = await qaResource.list(this.query).catch(() => {
        console.log('error')
      })

      this.listQuestion = data
      this.total = total
      this.loading = false
      window.scroll({
        top: 0,
        behavior: 'smooth'
      })
    },
    addQuestion () {
      if (!this.user?.uuid) {
        this.$bvModal.show('modal-login')
        return
      }
      this.$store.dispatch('setShowQuestionDialog', true)
    },
    searchQuestion (keyword = '') {
      this.query.keyword = keyword
      this.$router.push('/tim-kiem?keyword=' + encodeURIComponent(this.query.keyword))
    },
    openFilter () {
      this.showFilterDialog = true
    }
  }
}
</script>

<style lang="scss">
#app-hoi-bai {
  .add-question{
    background: var(--danger);
    box-shadow: 0px 0px 8px rgba(255, 30, 30, 0.5);
    border-radius: 22px;
    font-weight: 500;
    font-size: 16px;
    color: var(--white);
    padding:10px 30px 10px 25px;
    width: 175px;
    text-align: center;
    @media (max-width:1366px) {
      padding:7px 10px;
      width: 175px;
    }
    @media (max-width:576px) {
      width: 260px;
    }
    &:hover{
      box-shadow: 0px 0px 16px rgba(255, 30, 30, 0.5);
    }
  }
  .qa-action {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .qaa-title {
      font-style: normal;
      font-weight: 700;
      font-size: 48px;
      line-height: 150%;
      margin-bottom: 13px;

      .title-left {
        color: #F48C06;
      }

      .title-right {
        color: #4643F0;
      }
    }

    .qaa-description {
      font-style: normal;
      font-weight: 400;
      font-size: 1.5rem;
      line-height: 160%;
      color: #22313F;
      margin-bottom: 24px;

      p {
        margin-bottom: 0;
      }
    }

    .qaa-btn {
      background: #4086FF;
      border-radius: 10000px;
      width: 320px;
      padding: 19px 0;
      text-align: center;
      font-style: normal;
      font-weight: 600;
      font-size: 28px;
      line-height: 150%;
      color: #FFFFFF;

      img {
        margin-right: 15px;
      }
    }

    .qaa-bonus {
      margin-top: 22px;
      color: #4086FF;
      font-weight: 600;

      span {
        color: #FFA216;
      }
    }
  }

  &.intro-page-main {
    .intro-banner {
      width: 100%;
      position: relative;
      @media (max-width: 1400px) {
        height: fit-content;
      }

      &-bg {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .questions-section {
      padding-top: 29px;
      padding-bottom: 113px;
      @media (max-width: 1366px) {
        padding-top: 24px;
        padding-bottom: 40px;
      }
      @media (max-width: 560px) {
        padding-top: 16px;
        padding-bottom: 27px;
      }

      .qs-left {
        padding-top: 8px;

        .qsl-title {
          text-transform: uppercase;
          font-style: normal;
          font-weight: 700;
          font-size: 32px;
          line-height: 42px;
          color: #4086FF;
          margin-bottom: 36px;
          @media (max-width: 1366px) {
            font-size: 28px;
            line-height: 36px;
          }
        }

        .qsl-subject {
          background: #F1F6FF;
          border-radius: 16px;
          .qsls-item-noi-quy{
            .rules{
              width: 23px;
            }
            height: 65px;
            display: block;
            position: relative;
            padding: 15px 15px 15px 30px;
            font-style: normal;
            font-weight: 500;
            font-size: 1.125rem;
            color: #0F1B31;
            @media (max-width: 1366px) {
              height: 57px;
              padding: 15px 15px 15px 30px;
            }
          }
          &.active {
            color: #4086FF;
            background: #E0E8F9;

            &:after {
              display: block;
              content: '';
              position: absolute;
              right: 12px;
              top: 50%;
              transform: translateY(-50%);
              width: 3px;
              height: 24px;
              background: #4086FF;
              border-radius: 2px;
            }
          }
          &:hover {
            color: #4086FF;
            background: #E0E8F9;
          }

          &:first-child {
            border-top-left-radius: 16px;
            border-top-right-radius: 16px;
          }

          &:last-child {
            border-bottom-left-radius: 16px;
            border-bottom-right-radius: 16px;
          }

          img {
            margin-right: 13px;
            margin-top: -4px;
          }
          .qsls-item {
            height: 65px;
            display: block;
            position: relative;
            padding: 22px 17px 0 35px;
            font-style: normal;
            font-weight: 500;
            font-size: 1.125rem;
            color: #0F1B31;
            @media (max-width: 1366px) {
              height: 57px;
              padding: 19px 15px 0 30px;
            }
            &.active {
              color: #4086FF;
              background: #E0E8F9;

              &:after {
                display: block;
                content: '';
                position: absolute;
                right: 12px;
                top: 50%;
                transform: translateY(-50%);
                width: 3px;
                height: 24px;
                background: #4086FF;
                border-radius: 2px;
              }
            }

            &:hover {
              color: #4086FF;
              background: #E0E8F9;
            }

            &:first-child {
              border-top-left-radius: 16px;
              border-top-right-radius: 16px;
            }

            &:last-child {
              border-bottom-left-radius: 16px;
              border-bottom-right-radius: 16px;
            }

            img {
              //margin-right: 13px;
              width: 23px;
              margin-top: -4px;
            }
          }
        }
      }

      .qs-right {
        .qsr-input {
          position: relative;
          margin-bottom: 46px;
          @media (max-width: 1366px) {
            margin-bottom: 24px;
          }

          input {
            width: 100%;
            height: 56px;
            background: #F9F9F9;
            border: 1px solid #EEEEEE;
            border-radius: 28px;
            font-style: normal;
            font-weight: 400;
            font-size: 1rem;
            line-height: 19px;
            color: #22313F;
            padding-left: 50px;
            @media (max-width: 560px) {
              height: 50px;
            }

            &::placeholder {
              color: #CBCBCB;
            }
          }

          .search-btn {
            background: inherit;
            border: none;
            position: absolute;
            left: 12px;
            top: 50%;
            transform: translateY(-50%);
            @media (max-width: 560px) {
              left: 8px;
            }
          }
        }

        .qsrl-title {
          font-style: normal;
          font-weight: 700;
          font-size: 1.5rem;
          line-height: 31px;
          color: #333333;
          @media (max-width: 1366px) {
            font-size: 1.25rem;
            line-height: 24px;
          }
          @media (max-width: 560px) {
            font-size: 1.125rem;
            line-height: 20px;
          }
        }

        .qsrl-filter {
          cursor: pointer;

          p {
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            margin: 0 5px 0 8px;
            color: #333333;
          }
        }

        .qsr-list {
          .el-loading-spinner {
            margin-top: 32px;
          }

          .qsr-home-page {
            padding-bottom: 38px;
            @media (max-width: 560px) {
              padding-bottom: 24px;
            }
          }

          .no-question {
            padding: 120px 0;
            border: 1px solid #E6E6E6;
            box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
            border-radius: 18px;
            margin-top: 32px;
            @media (max-width: 560px) {
              padding: 80px 0;
              margin-top: 16px;
            }

            img {
              width: 393px;
              aspect-ratio: 393 / 371;
              @media (max-width: 560px) {
                width: 143px;
              }
            }

            &-text {
              padding-top: 12px;
              font-weight: 700;
              font-size: 1.5rem;
              line-height: 31px;
              color: #A2ABBC;
              @media (max-width: 560px) {
                padding-top: 0;
                font-size: 1rem;
                line-height: 20px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
