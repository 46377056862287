<template>
  <div class="data-viewed data-block p-0 position-relative bg-white" v-show="listView.length">
    <div class="swiper mySwiper px-3 px-lg-4" ref="mySwiper">
      <p class="data-block-title font-weight-bold color-darkgrey pb-3">Vừa xem</p>
      <div class="swiper-wrapper align-items-stretch">
        <div class="swiper-slide inner-content-wt" v-for="(item, ind) in listView" :key="ind">
          <DocumentViewed v-if="item.type === 'DOC'" :item="item" class="h-100" type="doc"/>
          <DocumentViewed v-if="item.type === 'PDF'" :item="item" class="h-100" type="pdf"/>
          <VideoViewed v-if="item.type === 'VIDEO'" :item="item" class="h-100"/>
          <QuizViewed v-if="item.type === 'EXAM'" :item="item" class="h-100"/>
        </div>
      </div>
      <div class="swiper-pagination"></div>
    </div>
    <div class="container sw-nav-container">
      <div class="navigator-btn">
        <div class="just-watch-nav-prev btn-nav-prev custom-navigator-btn position-absolute d-flex align-items-center justify-content-center pointer"><img src="~/assets/icons/library/chevron-right.svg" alt=""/></div>
        <div class="just-watch-nav-next btn-nav-next custom-navigator-btn position-absolute d-flex align-items-center justify-content-center pointer"><img src="~/assets/icons/library/chevron-right.svg" alt=""/></div>
      </div>
    </div>
  </div>
</template>

<script>
import DocumentViewed from '~/components/library-v2/document/viewed.vue'
import VideoViewed from '~/components/library-v2/video/viewed.vue'
import QuizViewed from '~/components/library-v2/quiz/viewed.vue'
import Resource from '~/common/api/resource'

const viewedResource = new Resource('library/view/latest')
export default {
  components: {
    DocumentViewed,
    VideoViewed,
    QuizViewed
  },
  data () {
    return {
      mySwiper: null,
      listView: []
    }
  },
  async created () {
    const token = this.$store.state.token
    const {data} = await viewedResource.list({limit: 10}, token)
    this.listView = data
  },
  mounted () {
    const element = this.$refs.mySwiper
    this.mySwiper = this.getSwiper(element, 4, '.just-watch-nav-next', '.just-watch-nav-prev')
  },
  beforeDestroy () {
    if (Array.isArray(this.mySwiper)) {
      this.mySwiper.forEach(o => o?.destroy())
    } else {
      this.mySwiper?.destroy()
    }
  },
  methods: {
    getSwiper (element, slidesPerView, nextEl, prevEl) {
      if (!window.Swiper || !element) {
        return
      }
      return new window.Swiper(element, {
        spaceBetween: 24,
        keyboard: {
          enabled: true
        },
        autoplay: true,
        watchSlidesProgress: true,
        breakpointsBase: 'container',
        breakpoints: {
          400: {
            slidesPerView: 1
          },
          1000: {
            slidesPerView: (slidesPerView - 1)
          },
          1300: {
            slidesPerView
          }
        },
        navigation: {
          nextEl,
          prevEl
        },
        on: {
          beforeInit () {
          },
          init () {
          },
          afterInit () {
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.data-viewed {
  .mySwiper {
    padding-top: 1.5rem;
    padding-bottom: 1.87rem;
    @media (max-width: 1366px) {
    }
    @media (max-width: 1200px) {
    }
    @media (max-width: 992px) {
    }
    @media (max-width: 768px) {
    }
    @media (max-width: 576px) {
    }

    .swiper-wrapper {
      height: 9.35rem;
    }
  }

  &.data-block {
    border-radius: 8px;

    .data-block-title {
      line-height: 1.2px;
      font-size: 1.5rem;
      @media (max-width: 1366px) {
      }
      @media (max-width: 1200px) {
      }
      @media (max-width: 992px) {
      }
      @media (max-width: 768px) {
      }
      @media (max-width: 576px) {
        font-size: 1.2rem;
      }
    }

    .custom-navigator-btn {
      width: 2rem;
      height: 2rem;
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
      border-radius: 100%;
      z-index: 99;
      background-color: #fff;
      top: calc(50% + 1.3rem);

      &.swiper-button-disabled {
        background-color: #828282bd !important;

        img {
          filter: brightness(0) invert(1) opacity(0.7);
        }
      }

      &.btn-nav-prev {
        left: 6px;
        transform: translateY(-50%);
      }

      &.btn-nav-next {
        right: 6px;
        transform: translateY(-50%);

        img {
          transform: rotateZ(180deg);
        }
      }
    }
  }
}
</style>
