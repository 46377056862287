<template>
  <div class="content-introduce container">
    <client-only>
      <h4 class="font-weight-bold text-center mt-3" style="font-size: 18px;">CHÍNH SÁCH THANH TOÁN</h4>
      <p><strong>Ch&uacute;ng t&ocirc;i l&agrave; C&ocirc;ng ty Cổ phần C&ocirc;ng nghệ v&agrave; Dịch vụ viễn th&ocirc;ng
        Mặt trời (Suntech Media)</strong> c&oacute; địa chỉ trụ sở H&agrave; Nội.</p>
      <p>Vilearn l&agrave; một dự &aacute;n thuộc Học viện Vilearn mang trong m&igrave;nh sứ mệnh n&acirc;ng cao năng
        lực cho c&aacute;c tổ chức c&oacute; tư duy tiến bộ th&ocirc;ng qua c&aacute;c giải ph&aacute;p đ&agrave;o tạo v&agrave;
        huấn luyện hiện đại.</p>
      <p>Vilearn đi ti&ecirc;n phong trong c&ocirc;ng cuộc hiện đại h&oacute;a phương ph&aacute;p học tập cho c&aacute;c
        doanh nghiệp tại Việt Nam. Ch&uacute;ng t&ocirc;i x&acirc;y dựng c&aacute;c kh&oacute;a học dựa tr&ecirc;n nền
        tảng Vilearn micro-learning kết hợp với c&aacute;c nguồn kiến thức uy t&iacute;n tr&ecirc;n thế giới. C&aacute;c
        video b&agrave;i học được thiết kế sinh động, ngắn gọn mang t&iacute;nh ứng dụng cao đem lại trải nghiệm học tập
        nhanh nhất, hấp dẫn nhất.</p>
      <p>Trang th&ocirc;ng tin ch&iacute;nh thức&nbsp;Vilearn.vn</p>
      <p>Khi qu&yacute; kh&aacute;ch h&agrave;ng truy cập v&agrave;o trang th&ocirc;ng tin của ch&uacute;ng t&ocirc;i c&oacute;
        nghĩa l&agrave; qu&yacute; kh&aacute;ch đồng &yacute; với c&aacute;c điều khoản n&agrave;y. Ch&uacute;ng t&ocirc;i
        c&oacute; quyền thay đổi, chỉnh sửa, th&ecirc;m hoặc lược bỏ c&aacute;c nội dung trong Ch&iacute;nh s&aacute;ch
        v&agrave; Điều khoản dịch vụ để ph&ugrave; hợp hơn với t&igrave;nh h&igrave;nh thực tế v&agrave; phục vụ kh&aacute;ch
        h&agrave;ng tốt hơn. C&aacute;c thay đổi c&oacute; hiệu lực ngay khi được đăng tr&ecirc;n trang web m&agrave; kh&ocirc;ng
        cần th&ocirc;ng b&aacute;o trước. V&agrave; khi qu&yacute; kh&aacute;ch tiếp tục sử dụng trang web, sau khi c&oacute;
        c&aacute;c thay đổi c&oacute; nghĩa l&agrave; qu&yacute; kh&aacute;ch chấp nhận với những thay đổi
        đ&oacute;.</p>
      <p>Qu&yacute; kh&aacute;ch h&agrave;ng vui l&ograve;ng kiểm tra thường xuy&ecirc;n để cập nhật những thay đổi của
        ch&uacute;ng t&ocirc;i.</p>
      <strong> H&igrave;nh thức thanh to&aacute;n</strong>
      <p>Vilearn cung cấp hai phương thức thanh to&aacute;n l&agrave; thanh to&aacute;n trực tuyến v&agrave; chuyển
        khoản.</p>
      <ol style="margin-left: 40px">
        <li>Thanh to&aacute;n Trực tuyến: Th&ocirc;ng qua c&aacute;c cổng thanh to&aacute;n trực tuyến phổ biến tại Việt
          Nam như VNPay, VNPT ePay, v.v&hellip; đ&atilde; được Vilearn k&yacute; kết hợp t&aacute;c v&agrave; t&iacute;ch
          hợp với hệ thống của Vilearn.
        </li>
        <li>Chuyển khoản: Người học thực hiện chuyển khoản v&agrave;o t&agrave;i khoản ng&acirc;n h&agrave;ng của
          Vilearn, đ&atilde; được đăng k&yacute; với sở Kế hoạch v&agrave; Đầu tư th&agrave;nh phố H&agrave; Nội. Th&ocirc;ng
          tin t&agrave;i khoản của Vilearn:
          <ul>
            <li>Ng&acirc;n h&agrave;ng:&nbsp;8822588888</li>
            <li>Số t&agrave;i khoản: Ng&acirc;n h&agrave;ng TMCP S&agrave;i g&ograve;n &ndash; H&agrave; nội, chi nh&aacute;nh
              trung t&acirc;m kinh doanh
            </li>
            <li>Chủ t&agrave;i khoản:&nbsp;<strong>C&ocirc;ng ty Cổ phần C&ocirc;ng nghệ v&agrave; Dịch vụ viễn th&ocirc;ng
              Mặt trời (Suntech Media)</strong></li>
          </ul>
        </li>
        <li>Thanh to&aacute;n gi&aacute;n tiếp: Th&ocirc;ng qua việc trừ cước t&agrave;i khoản viễn th&ocirc;ng di động
          của kh&aacute;ch h&agrave;ng. C&ocirc;ng ty Mặt Trời k&yacute; kết hợp đồng hợp t&aacute;c cung cấp dịch vụ
          với c&aacute;c nh&agrave; mạng: Vinaphone, Viettel, Mobifone&hellip;v&agrave; t&iacute;ch hợp hệ thống thanh
          to&aacute;n cho Vilearn.
        </li>
      </ol>
      <p>Nếu người học lựa chọn phương thức thanh to&aacute;n trực tuyến th&igrave; ngay sau khi thanh to&aacute;n được
        tiến h&agrave;nh th&agrave;nh c&ocirc;ng qua c&aacute;c cổng thanh to&aacute;n trực tuyến m&agrave; Vilearn đ&atilde;
        hợp t&aacute;c, hệ thống sẽ tự động gửi c&aacute;c th&ocirc;ng tin sử dụng dịch vụ tới h&ograve;m thư hoặc qua c&aacute;c
        dịch vụ li&ecirc;n lạc kh&aacute;c như Zalo, SMS, Facebook Messenger, v.v&hellip; m&agrave; kh&aacute;ch h&agrave;ng
        đ&atilde; cung cấp.</p>
      <p>Với trường hợp kh&aacute;ch h&agrave;ng thanh to&aacute;n th&ocirc;ng qua chuyển khoản. Bộ phận chăm s&oacute;c
        kh&aacute;ch h&agrave;ng của Vilearn sẽ kiểm tra th&ocirc;ng tin v&agrave; cập nhật để hệ thống gửi th&ocirc;ng
        tin dịch vụ tới h&ograve;m thư hoặc qua c&aacute;c dịch vụ li&ecirc;n lạc kh&aacute;c như Zalo, SMS, Facebook
        Messenger, v.v&hellip; m&agrave; kh&aacute;ch h&agrave;ng đ&atilde; cung cấp trong v&ograve;ng 24 giờ.</p>
      <p>Với kh&aacute;ch h&agrave;ng l&agrave; tổ chức, doanh nghiệp, Vilearn sẽ chuyển th&ocirc;ng tin tới k&ecirc;nh
        li&ecirc;n lạc ch&iacute;nh thức m&agrave; hai b&ecirc;n đ&atilde; thống nhất với nhau.</p>
    </client-only>
  </div>
</template>
<script>
export default {
  name: 'DieuKhoanSuDung'
}
</script>

<style scoped>
.content-introduce {
  padding: 15px;
}

ul {
  margin-left: 40px !important;
}
</style>
