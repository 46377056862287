<template>
  <div class="qsrl-items">
    <div class="item" v-for="question in data" :key="'question-' + question.id">
      <nuxt-link :to="getLink(question.id)">
        <div class="item-top">
          <div class="item-class-subject">
            {{ getClassName(question.class_id) }} &#8226; {{ getSubjectName(question.category_id) }}
          </div>
          <!--          <div class="item-type">{{ question.question_type === 2 ? 'Hỏi nhanh' : 'Hỏi video' }}</div>-->
          <div class="item-time d-flex align-items-center">
            <img src="~/assets/icons/question/time.png" alt="thời gian" class="d-lg-block d-none">{{ question.created_at | formatDateDetail(', ') }}
          </div>
          <div
            v-if="showStatus"
            class="item-status ml-auto"
            :style="{color: getStatusData(question.status).color, background: getStatusData(question.status).background}"
          >
            {{ getStatusData(question.status).text }}
          </div>
        </div>
        <div class="item-date-time d-none align-items-center">
          <img src="~/assets/icons/question/time.png" alt="thời gian" class="d-lg-block d-none">{{ question.created_at | formatDateDetail(', ') }}
        </div>
        <div class="item-content">
          <vue-mathjax :formula="question.content" :safe="false"></vue-mathjax>
        </div>
        <div v-if="question.image.length" class="item-image">
          <el-image fit="contain" v-for="(item, index) in question.image" :key="index" :src="item" class="image-question"/>
        </div>
        <div v-if="showDetailButton" class="item-btn">
          <div class="ib-btn pointer">Xem chi tiết</div>
        </div>
        <div v-if="question.percent_similar" class="item-footer d-flex align-items-center">
          <p class="percent-similar">Giống: <span>{{ question.percent_similar }}%</span></p>
          <div class="ib-btn pointer">Xem chi tiết</div>
        </div>
      </nuxt-link>
    </div>
  </div>
</template>
<script>

export default {
  props: {
    data: {
      type: Array,
      default () {
        return []
      }
    },
    showDetailButton: {
      type: Boolean,
      default: true
    },
    showStatus: {
      type: Boolean,
      default: false
    },
    fromSearch: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    getLink (questionId) {
      return this.fromSearch ? '/cau-hoi/' + questionId + '?from=search' : '/cau-hoi/' + questionId
    },
    getStatusData (value) {
      switch (value) {
        case 2:
          return {
            text: 'Đợi trả lời',
            color: '#FFA216',
            background: 'rgba(255, 162, 22, 0.1)'
          }
        case 1:
          return {
            text: 'Đã trả lời',
            color: '#00BC3C',
            background: '#DFF3E7'
          }
        case 3:
          return {
            text: 'Hoàn trả',
            color: '#FF2103',
            background: 'linear-gradient(329.29deg, rgba(255, 33, 3, 0.1) 10.4%, rgba(255, 33, 3, 0.1) 98.47%)'
          }
        default:
          return {
            text: '',
            color: '',
            background: ''
          }
      }
    },
    getSubjectName (id) {
      const subjects = this.$store.state.context.subjects
      return subjects.find(item => item.id === id).name
    },
    getClassName (id) {
      const classes = this.$store.state.context.classes
      return classes.find(item => item.id === id).name
    }
  }
}
</script>

<style lang="scss" scoped>
.qsrl-items {
  .item {
    cursor: pointer;
    border: 1px solid #E6E6E6;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    border-radius: 18px;
    padding: 22px;
    margin-top: 32px;
    @media (max-width: 1366px) {
      margin-top: 24px;
      padding: 18px;
    }
    @media (max-width: 560px) {
      margin-top: 16px;
      padding: 12px;
    }
  }

  .item-top {
    display: flex;
    align-items: center;
    margin-bottom: 22px;
    @media (max-width: 560px) {
      margin-bottom: 16px;
    }

    .item-class-subject {
      background: rgba(64, 134, 255, 0.16);
      border-radius: 19.5px;
      padding: 9px 13px;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #4086FF;
      @media (max-width: 560px) {
        font-size: 12px;
        line-height: 16px;
        padding: 5px 12px;
      }
    }

    .item-type {
      margin-left: 14px;
      background: rgba(255, 162, 22, 0.16);
      border-radius: 19.5px;
      padding: 9px 13px;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #FFA216;
      @media (max-width: 560px) {
        font-size: 12px;
        line-height: 16px;
        padding: 5px 12px;
        margin-left: 8px;
      }
    }

    .item-time {
      margin-left: auto;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.1px;
      color: #CBCBCB;
      @media (max-width: 1366px) {
        font-size: 14px;
      }
      @media (max-width: 560px) {
        font-size: 12px;
      }

      img {
        width: 20px;
        aspect-ratio: 1;
        margin-right: 11px;
        vertical-align: text-bottom;
        @media (max-width: 1366px) {
          width: 16px;
          font-size: 14px;
          margin-right: 8px;
        }
      }
    }

    .item-status {
      border-radius: 8px;
      padding: 6px;
      width: 140px;
      line-height: 24px;
      font-weight: 500;
      text-align: center;
      @media (max-width: 1366px) {
        width: 110px;
      }
      @media (max-width: 560px) {
        width: auto;
        background: inherit !important;
      }
    }
  }

  .item-date-time {
    margin-left: auto;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #CBCBCB;
    @media (max-width: 560px) {
      font-size: 12px;
    }

    img {
      margin-right: 11px;
      vertical-align: text-bottom;
    }
  }

  .item-content {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.1px;
    word-wrap: break-word;
    color: #22313F;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    @media (max-width: 1366px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  .item-image {
    margin-top: 24px;
    @media (max-width: 560px) {
      margin-top: 16px;
    }

    .image-question {
      display: block;
      margin: 0 auto 18px;
      width: 100%;
      max-width: 500px;
      max-height: 210px;
      object-fit: cover;
      @media (max-width: 1366px) {
        margin: 0 auto 14px;
        max-height: 168px;
      }
    }
  }

  .item-btn {
    margin-top: 18px;
    text-align: center;
    @media (max-width: 560px) {
      margin-top: 14px;
      text-align: right;
    }

    .ib-btn {
      display: inline-block;
      width: 155px;
      background: #4086FF;
      border-radius: 20px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #FFFFFF;
      text-align: center;
      padding: 11px 0;
      @media (max-width: 1366px) {
        width: 140px;
        padding: 10px 0;
      }
      @media (max-width: 560px) {
        padding: 6px 10px;
        width: auto;
      }
    }
  }

  .item-footer {
    margin-top: 30px;
    padding: 24px 0 0;
    border-top: 1px solid #EEEEEE;
    @media (max-width: 1366px) {
      margin-top: 24px;
      padding: 18px 0 0;
    }
    @media (max-width: 560px) {
      margin-top: 16px;
      padding: 14px 0 0;
    }

    .percent-similar {
      font-weight: 500;
      font-size: 18px;
      color: #22313F;
      margin-bottom: 0;
      margin-right: 50px;
      @media (max-width: 1366px) {
        font-size: 16px;
        margin-right: 20px;
      }

      span {
        color: #4086FF
      }
    }

    .ib-btn {
      display: inline-block;
      width: 155px;
      background: #4086FF;
      border-radius: 20px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #FFFFFF;
      text-align: center;
      padding: 11px 0;
      @media (max-width: 1366px) {
        width: 140px;
        padding: 10px 0;
      }
      @media (max-width: 560px) {
        padding: 6px 10px;
        width: auto;
      }
    }
  }
}
</style>
